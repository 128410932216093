import React, { useState } from 'react'
import cn from 'classnames'
import _ from 'lodash'

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

const Input = (props) => {
  const {
    debounceCallback,
    debounceCallbackTime = 2000,
    errorAbsolute = false,
    small = false,
    className = '',
    children,
    onChange,
    label,
    error,
    icon,
    prefix,
    toLowerCase = true,
    field,
    form,
    ...rest
  } = props

  const [t, setT] = useState(null)

  const hasAlert = form?.dirty && _.get(form?.errors, field?.name) && _.get(form?.touched, field?.name)

  const handleOnChange = (e) => {
    onChange ? onChange(e?.target?.value) : form?.setFieldValue(field?.name, e?.target?.value)
    if (t) clearTimeout(t)
    setT(setTimeout(() => debounceCallback && debounceCallback(), debounceCallbackTime))
  }

  return (
    <label className="input">
      {label && <p className="mb-1 font-medium">{toLowerCase ? capitalizeFirstLetter(label?.toLowerCase()) : label}</p>}
      <div className={cn('input-inner', icon && 'input-icon', prefix && 'input-prefix')}>
        {prefix && <span className="p text-cp-gray-40 prefix">{prefix}</span>}
        <input
          className={cn('input-default', small && 'input-small', className, hasAlert && 'border-cp-alert-100')}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type={rest?.type ?? 'text'}
          {...field}
          onChange={(e) => handleOnChange(e)}
          {...rest}
        />
        {icon && <span className="icon">{icon}</span>}
      </div>

      {hasAlert && (
        <p className={cn('small font-medium text-cp-alert-100 flex items-center mt-1', errorAbsolute && 'absolute')}>
          <i className="icon-tooltip mr-1" />
          {_.get(form?.errors, field?.name)}
        </p>
      )}
    </label>
  )
}

export default Input
