import React, { useEffect, Suspense, useState } from 'react'
import cn from 'classnames'
import { Switch } from 'react-router-dom'
import Learn from './Learn'

import Layout from '../Layout'
import Loading from '../Loading'
import Headers from '../Headers'

import Sidebar from './components/Sidebar'
import Header from './components/Header'

import Authmiddleware from '../../../middleware/auth'
import styles from './AppRender.module.scss'
import FastLink from '../FastLink'
import alert from 'react-hot-toast'

import { useGetFormTypesQuery, useStopImpersonateKioskMutation } from '../../../modules/services/workspaceApi.js'

const AppRender = ({ routes, currentRoute }) => {
  const [toggle, setToggle] = useState(true)
  const [mobile_toggle, setMobileToggle] = useState(false)

  const [mini, setMini] = useState(false)
  const [isOpenLearn, setLearnOpen] = useState(false)
  const { data: formTypes = [] } = useGetFormTypesQuery()
  const [stopImpersonateKiosk, { isLoading }] = useStopImpersonateKioskMutation()

  useEffect(() => {
    setToggle(!!currentRoute?.sub_sidebar)
    setMobileToggle(false)
  }, [currentRoute])

  const isEditor = currentRoute.type === 'form-layout'
  const isCreateFormJourney = currentRoute.type === 'create-form'
  const isSchedulingEditor = currentRoute.type === 'scheduling-editor'

  const sidebarActive = !isEditor && !isCreateFormJourney && !isSchedulingEditor
  const headerActive = !isEditor && !isCreateFormJourney && !isSchedulingEditor
  const learnActive = true

  const prevToken = window.localStorage.getItem('CP_PREV_TOKEN')
  const impersonateUserId = window.localStorage.getItem('CP_IMPERSONATE_DEFAULT_USER')

  return (
    <div
      className={cn(styles.app_layout, { [styles.sidebarActive]: !sidebarActive, [styles.editor]: isEditor })}
      data-mobile_sidebar={mobile_toggle}
      data-mini_sidebar={mini}
      data-has_subsidebar={toggle}
    >
      <main className={cn(styles.app_main)}>
        {/*  styles.withAnimation */}
        {/* {learnActive && <Learn isOpenLearn={isOpenLearn} setLearnOpen={() => setLearnOpen()} />} */}
        {sidebarActive && (
          <Sidebar
            setToggleMobile={setMobileToggle}
            toggleMobile={mobile_toggle}
            setMini={setMini}
            mini={mini}
            routes={routes}
            currentRoute={currentRoute}
          />
        )}
        {headerActive && (
          <Header
            setToggleMobile={setMobileToggle}
            toggleMobile={mobile_toggle}
            setLearnOpen={setLearnOpen}
            routes={routes}
            currentRoute={currentRoute}
          />
        )}
        {isEditor && (
          <Layout>
            <Headers />
          </Layout>
        )}

        {prevToken && (
          <div className="fixed z-full right-1 top-1/2 transform -rotate-90 -translate-y-1/2 origin-bottom-right">
            <button
              className="btn btn-danger"
              onClick={() => {
                stopImpersonateKiosk().then((response) => {
                  window.localStorage.setItem('CP_TOKEN', prevToken)
                  window.localStorage.removeItem('CP_PREV_TOKEN')
                  window.localStorage.removeItem('CP_IMPERSONATE_DEFAULT_USER')

                  alert('You have successfully returned user', {
                    type: 'success',
                  })

                  window.location.href = '/'
                })
              }}
            >
              Cancel impersonate
            </button>
          </div>
        )}

        <section
          className={cn(
            styles.app_content,
            currentRoute.type === 'form-layout' && styles.no_margin,
            currentRoute.type === 'scheduling-editor' && styles.no_margin
          )}
        >
          <Suspense fallback={<Loading />}>
            <Switch>
              {routes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  exact={route?.exact}
                  component={route.component}
                  key={idx}
                  type={route.type}
                  // token={token}
                />
              ))}
            </Switch>
          </Suspense>
          {headerActive && <FastLink formTypes={formTypes} />}
        </section>
      </main>
    </div>
  )
}

export default AppRender
