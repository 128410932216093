import React from 'react'
import { combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import appReducer, { setClearAll } from './reducers/app'
import schedulingReducer from './reducers/scheduling'
// import { forms } from './services/formsApi'
import { integrations } from './services/integrationsApi'
import { webhooks } from './services/webhooksApi'
import { recordings } from './services/recordingsApi'
import { events } from './services/eventsApi'
import { auth } from './services/authApi'
import { user } from './services/userApi'
import { workspace } from './services/workspaceApi'
import { billing } from './services/billingApi'
import { notifications } from './services/notificationsApi'

// import { settings } from './services/settingsApi'
import alert from 'react-hot-toast'

export const rtkQueryErrorLogger =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    await next(action)
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        dispatch(setClearAll())
        // history.push('/login')
        // window.location.reload()
        return
      }
      if (action.payload.status === 422) {
        // form validation errors
        const errors = action?.payload?.data?.errors

        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
            if (value && !!value.length) {
              ;(typeof value === 'string' ? [value] : value).forEach((item) => {
                if (item) {
                  alert(item, {
                    type: 'error',
                    duration: 4000,
                  })
                  // toast.custom((t) => <Alert options={{ type: 'error' }} message={item} />, {
                  //   duration: 4000,
                  //   className: '__react-alert__',
                  // })
                }
              })
            }
          })
        }
        return
      }

      if (action?.payload?.data?.message) {
        alert(action?.payload?.data?.message, {
          type: 'error',
          duration: 4000,
        })
        // toast.custom((t) => <Alert options={{ type: 'error' }} message={action?.payload?.data?.message} />, {
        //   duration: 4000,
        //   className: '__react-alert__',
        // })
        return
      }

      // toast.custom(
      //   (t) => (
      //     <div className={`${t.visible ? 'animate-enter' : 'animate-leave'}`}>
      //       <div className="relative flex flex-row items-center bg-red-500 shadow rounded-md px-4 py-2">
      //         <div className="flex flex-row items-center w-auto">
      //           <div className="cp-text-tiny font-medium text-white">Something went wrong.</div>
      //         </div>
      //       </div>
      //     </div>
      //   ),
      //   {
      //     duration: 8000,
      //   }
      // )
    }
  }

const combinedReducer = combineReducers({
  app: appReducer,
  scheduling: schedulingReducer,
  [auth.reducerPath]: auth.reducer,
  [user.reducerPath]: user.reducer,
  [workspace.reducerPath]: workspace.reducer,
  [billing.reducerPath]: billing.reducer,
  // [forms.reducerPath]: forms.reducer,
  [integrations.reducerPath]: integrations.reducer,
  [webhooks.reducerPath]: webhooks.reducer,
  [recordings.reducerPath]: recordings.reducer,
  [events.reducerPath]: events.reducer,
  [notifications.reducerPath]: notifications.reducer,
  // [settings.reducerPath]: settings.reducer,
})

export const store = configureStore({
  reducer: (rootState, action) => {
    let state = rootState
    if (setClearAll.match(action)) {
      state = undefined
    }
    return combinedReducer(state, action)
  },

  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(auth.middleware)
      .concat(user.middleware)
      .concat(workspace.middleware)
      .concat(billing.middleware)
      // .concat(forms.middleware)
      .concat(integrations.middleware)
      .concat(recordings.middleware)
      .concat(events.middleware)
      .concat(notifications.middleware),
    // .concat(settings.middleware),
  ],
  devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

if (window.Cypress) {
  window.store = store
}
