import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import CopyInput from '../CopyInput'
import ClickOutside from '../../../lib/click-outside'
import styles from './previewButton.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormData, selectIsDirty, setDirty, setFormType, selectFormId } from '../../../modules/reducers/app'
import {
  useGetFormFieldTypesQuery,
  useGetFormQuery,
  useSaveFormMutation,
  usePublishFormMutation,
  useUpdateFormTemplateMutation,
} from '../../../modules/services/workspaceApi'
import { useHistory } from 'react-router'
import { isEqual } from 'lodash'

function PreviewButton(props) {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const [dropdown, setDropdown] = useState(false)

  const [canSave, setCanSave] = useState(false)
  const [canPublish, setPCanPublish] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isPublished, setIsPublished] = useState(false)

  const [updateError, setUpdateError] = useState(false)

  const isDirty = useSelector(selectIsDirty)
  const id = useSelector(selectFormId)
  const formData = useSelector(selectFormData)

  const { data: form, refetch } = useGetFormQuery(id)
  const { data: formFieldTypes, isSuccess: formFieldTypesLoaded } = useGetFormFieldTypesQuery()

  const [updateTemplate] = useUpdateFormTemplateMutation()
  const [saveForm, { isLoading: savingForm, isSuccess: formSaved }] = useSaveFormMutation()
  const [publishForm, { isLoading: publishingForm, isSuccess: formPublished }] = usePublishFormMutation()

  useEffect(() => {
    if (isDirty) {
      if (!isSaved && !isPublished) {
        setCanSave(true)
      }
      if (isPublished) {
        setIsPublished(false)
      }
      if (isSaved && canPublish) {
        setCanSave(true)
        setIsSaved(false)
        setPCanPublish(false)
      }
    } else {
      setCanSave(false)
      setPCanPublish(false)
    }
  }, [isDirty])

  useEffect(() => {
    if (form && form?.revision_id !== form?.published_revision_id) {
      setPCanPublish(true)
    }
  }, [form])

  useEffect(() => {
    if (formSaved) {
      setIsSaved(true)
      if (!isPublished) {
        setPCanPublish(true)
      }
    }
  }, [formSaved])

  useEffect(() => {
    if (formPublished) {
      setCanSave(false)
      setIsSaved(false)
      setPCanPublish(false)
      setIsPublished(true)
    } else if (form && form?.revision_id === form?.published_revision_id) {
      setPCanPublish(false)
    }
  }, [formPublished])

  if (!formFieldTypesLoaded) {
    return ''
  }

  const saveFormAction = () => {
    const action = saveForm

    const fixedStepParamFields = formData?.fields
      .reduce((arr, field) => {
        arr[Number(field.step)] = [...(arr[Number(field.step)] || []), field]
        return arr
      }, [])
      .filter((fields) => fields?.length)
      .map((fields, i) => fields.map((field) => ({ ...field, step: i + 1 })))
      .reduce((arr, fields) => {
        arr = [...(arr || []), ...fields]
        return arr
      }, [])

    const submitFormValues = {
      ...formData,
      dependent_fields: formData?.detail?.dependent_fields,
      fields: fixedStepParamFields?.map((item) => ({
        ...item,
        field_type_id: formFieldTypes?.find((field) => field.name === item.field_type)?.id,
        id: item.key,
      })),
    }

    if (isEqual(form.template.variables, submitFormValues.template.variables)) {
      action(submitFormValues).then((data) => {
        if (data?.error) {
          setUpdateError(true)
        } else {
          refetch()
          setUpdateError(false)
        }
      })
    } else {
      updateTemplate({
        name: 'string',
        description: `form-${formData?.form_template_id}`,
        is_public: false,
        is_old: false,
        variables: formData?.template?.variables,
      }).then((response) => {
        action({ ...submitFormValues, form_template_id: response.data.data.id }).then((data) => {
          if (data?.error) {
            setUpdateError(true)
          } else {
            refetch()
            setUpdateError(false)
          }
        })
      })
    }

    dispatch(setDirty(false))
    // dispatch(setSaved(true))
  }

  const publishFormAction = () => {
    const action = publishForm
    const submitFormValues = {
      ...formData,
      dependent_fields: formData?.detail?.dependent_fields,
      fields: formData?.fields?.map((item) => ({
        ...item,
        field_type_id: formFieldTypes?.find((field) => field.name === item.field_type)?.id,
        id: item.key,
      })),
    }

    action(submitFormValues).then(() => {
      setDropdown(true)
    })
    dispatch(setDirty(false))
    // dispatch(setSaved(true))
    // setPublishedAction(false)
  }

  const renderSaveButton = () => {
    return (
      <button
        className={cn('btn btn-publish', 'mr-3', isPublished ? 'btn-success' : 'btn-secondary')}
        disabled={(!isDirty && !canSave && !updateError) || isSaved || isPublished}
        onClick={() => saveFormAction()}
      >
        {isPublished ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.99999 15.172L19.192 5.979L20.607 7.393L9.99999 18L3.63599 11.636L5.04999 10.222L9.99999 15.172Z"
              fill="currentColor"
            />
          </svg>
        ) : (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 19V13H17V19H19V7.828L16.172 5H5V19H7ZM4 3H17L21 7V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9 15V19H15V15H9Z"
              fill="currentColor"
            />
          </svg>
        )}
        <span className="cp-text-tiny ml-1">
          {isPublished ? 'Published' : savingForm && !updateError ? 'Saving' : 'Save'}
        </span>
        {/* <span className="icon-wrapper">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 13.1719L16.95 8.22192L18.364 9.63592L12 15.9999L5.63599 9.63592L7.04999 8.22192L12 13.1719Z"
              fill="currentColor"
            />
          </svg>
        </span> */}
      </button>
    )
  }

  const renderPublishButton = () => {
    return (
      <div onClick={() => isPublished && setDropdown(!dropdown)}>
        <button
          className={cn('btn btn-publish', 'mr-3', 'btn-primary')}
          disabled={(!isDirty && !canPublish) || publishingForm}
          onClick={() => publishFormAction()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.92302 9.37004C1.41302 9.16504 1.41902 8.86004 1.95702 8.68104L21.043 2.31904C21.572 2.14304 21.875 2.43904 21.727 2.95704L16.273 22.043C16.123 22.572 15.798 22.596 15.556 22.113L11 13L1.92302 9.37004ZM6.81302 9.17004L12.449 11.425L15.489 17.507L19.035 5.09704L6.81202 9.17004H6.81302Z"
              fill="currentColor"
            />
          </svg>

          <span className="cp-text-tiny ml-1">
            {publishingForm ? 'Publishing' : isPublished ? 'Published' : 'Publish'}
          </span>
          {/* <span className="icon-wrapper">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 13.1719L16.95 8.22192L18.364 9.63592L12 15.9999L5.63599 9.63592L7.04999 8.22192L12 13.1719Z"
                fill="currentColor"
              />
            </svg>
          </span> */}
        </button>
      </div>
    )
  }

  return (
    <ClickOutside onClick={() => setDropdown(false)}>
      <div>
        <div className={styles.box}>
          {/* <button className='btn btn-secondary' type='button' onClick={() => createPublicTemplate()}>Create public themes</button> */}
          {props.pathname !== '/publish-as' && (
            <a className="flex items-center cursor-pointer" onClick={() => push(`/editor/${id}/preview`)}>
              <span className="mr-2 cp-text-tiny text-cp-gray-40"> Preview</span>
              <span className={styles.preview_icon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.60803 21 2.12203 17.12 1.18103 12C2.12103 6.88 6.60803 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2074 15.7635 20.3229 13.9883 20.777 12C20.3213 10.0133 19.205 8.24 17.6107 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.96173 5.00853 7.98372 5.70005 6.38941 6.97003C4.79509 8.24 3.6788 10.0133 3.22303 12C3.67713 13.9883 4.7927 15.7635 6.38717 17.0352C7.98164 18.3068 9.96056 18.9996 12 19ZM12 16.5C10.8066 16.5 9.66196 16.0259 8.81805 15.182C7.97414 14.3381 7.50003 13.1935 7.50003 12C7.50003 10.8065 7.97414 9.66193 8.81805 8.81802C9.66196 7.97411 10.8066 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.6631 14.5 13.299 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.299 9.76339 12.6631 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2323 10.2322C9.76342 10.7011 9.50003 11.337 9.50003 12C9.50003 12.663 9.76342 13.2989 10.2323 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z"
                    fill="var(--text-gray-100)"
                  />
                </svg>
              </span>
            </a>
          )}
          <div style={{ display: 'flex' }}>
            {canSave ? renderSaveButton() : canPublish ? renderPublishButton() : renderSaveButton()}
          </div>
          <div className={cn(styles.droprown, dropdown && styles.active)}>
            <button onClick={() => setDropdown(false)} className="absolute top-5 right-7 text-cp-gray-40">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
                  fill="rgb(161, 161, 161)"
                />
              </svg>
            </button>
            <h6 className="mb-2">Link to share</h6>
            <p className="mb-4 cp-text-tiny text-cp-gray-60">Share your form with anyone and start collecting.</p>
            <div className="flex items-center">
              <CopyInput link={`${form?.prefix_url}${form?.regular_form_prefix}${id}`} />
            </div>
            <hr className="border-cp-gray-20 mt-5" />
            <span className="divider-or">OR</span>
            <h6>Embed into your website</h6>
            <ul className="grid grid-cols-4 gap-4">
              {props?.formTypes &&
                !!props?.formTypes.length &&
                props?.formTypes.map((form, index) => (
                  <li key={index}>
                    <a
                      className="cursor-pointer"
                      onClick={() => {
                        dispatch(setFormType(form.key))
                        push(`/editor/${id}/publish-as`)
                      }}
                    >
                      <span className={cn(styles.icon_box, form.key === props.activeType && styles.active)}>
                        {form.key === 'regular-form' ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 3C20.552 3 21 3.448 21 4V20C21 20.552 20.552 21 20 21H4C3.448 21 3 20.552 3 20V4C3 3.448 3.448 3 4 3H20ZM11.189 13.158L5 14.25V19H12.218L11.188 13.158H11.189ZM19 5H11.781L14.249 19H19V5ZM9.75 5H5V12.218L10.842 11.188L9.75 5Z"
                              fill="var(--text-gray-100)"
                            />
                          </svg>
                        ) : form.key === 'backendless-form' ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM4 5V19H20V5H4ZM12 15H18V17H12V15ZM8.667 12L5.838 9.172L7.253 7.757L11.495 12L7.253 16.243L5.838 14.828L8.667 12Z"
                              fill="var(--text-gray-100)"
                            />
                          </svg>
                        ) : form.key === 'popup-form' ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM15 6H19V8H15V6Z"
                              fill="var(--text-gray-100)"
                            />
                          </svg>
                        ) : form.key === 'conversational-form' ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM8 10H16V12H8V10Z"
                              fill="var(--text-gray-100)"
                            />
                          </svg>
                        ) : (
                          ''
                        )}
                      </span>
                      <p className="cp-text-sm text-left mt-1">{form.name}</p>
                    </a>
                  </li>
                ))}
            </ul>
          </div>

          {/* <div className={cn(styles.droprown, dropdown && styles.active)}>
            <ul className="flex flex-col space-y-2">
              <li className="flex items-center">
                <span className="icon-restore mr-2" />
                <span className="cp-text-tiny">Restore to last version</span>
              </li>
              <li className="flex items-center">
                <span className="icon-restore mr-2" />
                <span className="cp-text-tiny">Restore to 2 days ago version</span>
              </li>
              <li className="flex items-center">
                <span className="icon-restore mr-2" />
                <span className="cp-text-tiny">Restore to 4 weeks ago version</span>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </ClickOutside>
  )
}

export default PreviewButton
