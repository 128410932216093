const styles = {
  container: (provided, state) => ({
    ...provided,
    pointerEvents: state.isDisabled ? null : null,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    backgroundColor: state.isDisabled ? '#FBFBFB' : 'var(--bg-white)',

    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    border: 'none',
    paddingLeft: '0',
    paddingRight: '0',
    fontSize: '16px',
    minHeight: '0px',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    height: '36px',
    input: {
      height: '1px',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    height: '19px',
    overflow: 'hidden',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '24px',
  }),
  option: (provided, isSelected) => ({
    ...provided,
    backgroundColor: 'var(--bg-white)',

    '&:hover': {
      backgroundColor: 'var(--gray-10)',
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#7D7E7E',
    padding: '0px',
    svg: {
      width: '16px',
      height: '16px',
    },
  }),
  // indicatorsContainer: (base) => ({
  //   ...base,
  //   display: 'none',
  // }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    width: 189,
    backgroundColor: 'var(--bg-gray-0)',
    boxShadow: '0px 10px 30px rgba(34, 34, 34, 0.05)',
    borderRadius: '6px',
    marginTop: '14px',
    padding: '0',
    marginLeft: '-16px',
    border: '1px solid var(--border-gray-20)',
  }),
}

export default styles
