import React, { useState } from 'react'
import cn from 'classnames'
import styles from './FormHeader.module.scss'
import Button from '../../Button'
import PreviewButton from '../../PreviewButton'
import { useLocation, NavLink } from 'react-router-dom'
// import MoreButton from '../../MoreButton'
import Select, { components } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFormId,
  selectFormData,
  selectFormName,
  selectIsDirty,
  setDirty,
  setFormName,
  setFormType,
} from '../../../../modules/reducers/app'

import { useGetFormSubmissionsQuery, useGetFormTypesQuery } from '../../../../modules/services/workspaceApi'
import CodeMirrorView from '../../CodeMirrorView'
import ClickOutside from '../../../../lib/click-outside'

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    pointerEvents: state.isDisabled ? null : null,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    backgroundColor: state.isDisabled ? '#FBFBFB' : 'var(--bg-white)',

    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    border: 'none',
    paddingLeft: '4px',
    paddingRight: '8px',
    fontSize: '16px',
    minHeight: '0px',
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    height: '24px',
    input: {
      height: '1px',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    height: '19px',
    overflow: 'hidden',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '24px',
  }),
  option: (provided, isSelected) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',

    '&:hover': {
      backgroundColor: 'var(--gray-10)',
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#7D7E7E',
    padding: '0px',
    svg: {
      width: '16px',
      height: '16px',
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    width: 200,
    border: '1px solid var(--bg-gray-20)',
    boxShadow: '0px 10px 30px rgba(34, 34, 34, 0.05)',
    borderRadius: '6px',
    marginTop: '4px',
    backgroundColor: 'var(--bg-gray-0)',
  }),
}

const icons = {
  'regular-form': 'form',
  'backendless-form': 'backend',
  'popup-form': 'popup',
  'conversational-form': 'conversational',
}

const FormHeader = (props) => {
  const form = useSelector(selectFormData)
  const formName = useSelector(selectFormName)
  const isDirty = useSelector(selectIsDirty)
  const id = useSelector(selectFormId)
  const dispatch = useDispatch()

  const { data: submissions } = useGetFormSubmissionsQuery(
    {
      id: id,
      page: 1,
      sort: '-created_at',
      search: '',
      start: '',
      finish: '',
    },
    { skip: !id }
  )

  const { data: formTypes } = useGetFormTypesQuery()
  const [showCodeMirror, setShowCodeMirror] = useState(false)

  const handleChange = (name) => {
    dispatch(setFormName(name))
    dispatch(setDirty(true))
  }
  const { Option } = components
  const CustomSelectOption = (props) => {
    return (
      <Option {...props}>
        <div className="flex items-center">
          <div className="rounded bg-gray-200 w-9 h-9 flex items-center justify-center">
            <span>
              {icons[props.data.key] === 'form' ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3C20.552 3 21 3.448 21 4V20C21 20.552 20.552 21 20 21H4C3.448 21 3 20.552 3 20V4C3 3.448 3.448 3 4 3H20ZM11.189 13.158L5 14.25V19H12.218L11.188 13.158H11.189ZM19 5H11.781L14.249 19H19V5ZM9.75 5H5V12.218L10.842 11.188L9.75 5Z"
                    fill="var(--text-gray-100)"
                  />
                </svg>
              ) : icons[props.data.key] === 'backend' ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM4 5V19H20V5H4ZM12 15H18V17H12V15ZM8.667 12L5.838 9.172L7.253 7.757L11.495 12L7.253 16.243L5.838 14.828L8.667 12Z"
                    fill="var(--text-gray-100)"
                  />
                </svg>
              ) : icons[props.data.key] === 'popup' ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM15 6H19V8H15V6Z"
                    fill="var(--text-gray-100)"
                  />
                </svg>
              ) : icons[props.data.key] === 'conversational' ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM8 10H16V12H8V10Z"
                    fill="var(--text-gray-100)"
                  />
                </svg>
              ) : (
                ''
              )}
            </span>
          </div>
          <p className="ml-2 cp-text-sm">{props.data.name}</p>
        </div>
      </Option>
    )
  }

  const CustomSelectValue = () => {
    return (
      <div className="w-6 h-6">
        {icons[form?.detail?.type] === 'form' ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 3C20.552 3 21 3.448 21 4V20C21 20.552 20.552 21 20 21H4C3.448 21 3 20.552 3 20V4C3 3.448 3.448 3 4 3H20ZM11.189 13.158L5 14.25V19H12.218L11.188 13.158H11.189ZM19 5H11.781L14.249 19H19V5ZM9.75 5H5V12.218L10.842 11.188L9.75 5Z"
              fill="var(--text-gray-100)"
            />
          </svg>
        ) : icons[form?.detail?.type] === 'backend' ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM4 5V19H20V5H4ZM12 15H18V17H12V15ZM8.667 12L5.838 9.172L7.253 7.757L11.495 12L7.253 16.243L5.838 14.828L8.667 12Z"
              fill="var(--text-gray-100)"
            />
          </svg>
        ) : icons[form?.detail?.type] === 'popup' ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM15 6H19V8H15V6Z"
              fill="var(--text-gray-100)"
            />
          </svg>
        ) : icons[form?.detail?.type] === 'conversational' ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM8 10H16V12H8V10Z"
              fill="var(--text-gray-100)"
            />
          </svg>
        ) : (
          ''
        )}
      </div>
    )
  }

  const location = useLocation()
  const { pathname } = location

  const validPaths = [`/editor/${id}`]

  return (
    <div className={cn(styles.header)}>
      <div className="flex items-center">
        <div className="flex-1 mobile_hide">
          <div className="flex items-center justify-start">
            {validPaths.includes(pathname) && (
              <span className="ml-2 flex items-center font-semibold cursor-pointer">
                {/* <Select
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.key}
                  styles={customStyles}
                  isSearchable={false}
                  value={form?.detail?.type}
                  onChange={(val) => {
                    dispatch(setDirty(true))
                    dispatch(setFormType(val.key))
                  }}
                  options={formTypes}
                  components={{ Option: CustomSelectOption, IndicatorsContainer: CustomSelectValue }}
                  // menuIsOpen={true}
                /> */}
                {/* <span className="icon-form cp-text-xl mr-4" /> */}
                <input
                  className="outline-none cp-text-tiny font-semibold w-full border-black pb-1 mt-1 border-solid border-b border-opacity-10"
                  style={{ maxWidth: '150px' }}
                  size=""
                  value={formName}
                  onChange={(e) => handleChange(e.target.value)}
                  // onBlur={handleBlur}
                />
                {isDirty && <span className="badged badged-warning badged-small ml-2">Draft</span>}
              </span>
            )}
          </div>
        </div>
        <div className="flex-1">
          <div className="flex items-center justify-center">
            <ul className="flex items-center flex-wrap md:flex-nowrap space-x-2 md:space-x-6">
              <li>
                <NavLink
                  exact
                  className={cn(styles.nav_item, 'mobile_hide')}
                  activeClassName={styles.active_nav}
                  to={`/editor/${id}`}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 3C20.552 3 21 3.448 21 4V20C21 20.552 20.552 21 20 21H4C3.448 21 3 20.552 3 20V4C3 3.448 3.448 3 4 3H20ZM11.189 13.158L5 14.25V19H12.218L11.188 13.158H11.189ZM19 5H11.781L14.249 19H19V5ZM9.75 5H5V12.218L10.842 11.188L9.75 5Z"
                      fill="#a1a1a1"
                    />
                  </svg>{' '}
                  <span className="cp-text-tiny">Build</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={cn(styles.nav_item)}
                  activeClassName={styles.active_nav}
                  to={`/editor/${id}/integrations`}
                >
                  <svg
                    className="mobile_hide"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 21.5001C4.067 21.5001 2.5 19.9331 2.5 18.0001C2.5 16.0671 4.067 14.5001 6 14.5001C7.585 14.5001 8.924 15.5541 9.355 17.0001H15V15.0001H17V9.2421L14.757 7.0001H9V9.0001H3V3.0001H9V5.0001H14.757L18 1.7561L22.243 6.0001L19 9.2411V15.0001H21V21.0001H15V19.0001H9.355C8.925 20.4461 7.585 21.5001 6 21.5001ZM6 16.5001C5.172 16.5001 4.5 17.1721 4.5 18.0001C4.5 18.8281 5.172 19.5001 6 19.5001C6.828 19.5001 7.5 18.8281 7.5 18.0001C7.5 17.1721 6.828 16.5001 6 16.5001ZM19 17.0001H17V19.0001H19V17.0001ZM18 4.5861L16.586 6.0001L18 7.4141L19.414 6.0001L18 4.5861ZM7 5.0001H5V7.0001H7V5.0001Z"
                      fill="#a1a1a1"
                    />
                  </svg>{' '}
                  <span className="cp-text-tiny">Integrations</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={cn(styles.nav_item)}
                  activeClassName={styles.active_nav}
                  to={`/editor/${id}/publish-as`}
                >
                  <svg
                    className="mobile_hide"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 14H11C9.3596 13.9994 7.75023 14.4471 6.34588 15.2949C4.94152 16.1427 3.7956 17.3582 3.032 18.81C3.01054 18.5405 2.99986 18.2703 3 18C3 12.477 7.477 8 13 8V2.5L23.5 11L13 19.5V14ZM11 12H15V15.308L20.321 11L15 6.692V10H13C11.8503 9.99871 10.7138 10.2458 9.66839 10.7244C8.62299 11.203 7.69332 11.9018 6.943 12.773C8.23432 12.2612 9.61096 11.9989 11 12Z"
                      fill="#a1a1a1"
                    />
                  </svg>{' '}
                  <span className="cp-text-tiny">Publish</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={cn(styles.nav_item)}
                  activeClassName={styles.active_nav}
                  to={`/editor/${id}/submissions`}
                >
                  <svg
                    className="mobile_hide"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM8 10H16V12H8V10Z"
                      fill="#a1a1a1"
                    />
                  </svg>{' '}
                  <span className="cp-text-tiny">Submissions</span>
                  <span className="badged badged-warning badged-small ml-1 mobile_hide">
                    {submissions?.page?.total}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={cn(styles.nav_item)}
                  activeClassName={styles.active_nav}
                  to={`/editor/${id}/analytic`}
                >
                  <i className="icon-analytic mobile_hide"></i>
                  <span className="cp-text-tiny">Analytics</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-1 mobile_hide">
          {validPaths.includes(pathname) && (
            <div className="">
              <div className="flex items-center justify-end">
                {form?.detail?.type === 'backendless-form' && (
                  <ClickOutside onClick={() => setShowCodeMirror(false)}>
                    <div className="mr-2">
                      {showCodeMirror && <CodeMirrorView data={form} inBuilder />}

                      <span className="relative inline-flex rounded-md shadow mr-3">
                        <Button className="btn relative text-black" onClick={() => setShowCodeMirror(!showCodeMirror)}>
                          Show code
                        </Button>
                        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                        </span>
                      </span>
                    </div>
                  </ClickOutside>
                )}

                <PreviewButton pathname={pathname} activeType={props?.type} formTypes={formTypes} />
                {/* <Button className="btn-blue ml-3" loading>
                Upgrade
              </Button> */}
              </div>
              {/* <div className="flex lg:hidden  items-center justify-end">
                <Button className="btn-primary" loading>
                  <span className="icon-membership mr-2" />
                  Publish
                </Button>
                <MoreButton />
              </div> */}
            </div>
          )}
        </div>
      </div>
      {/* <div className="border-t border-b border-cp-gray-10 dark:border-cp-gray-0 py-3 block lg:hidden">
        <ul className="flex justify-between px-4 sm:px-5">
          <li>
            <NavLink className={cn(styles.nav_item)} activeClassName={styles.active_nav} to={`/editor/${id}`}>
              <span className="cp-text-tiny">Build</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={cn(styles.nav_item)}
              activeClassName={styles.active_nav}
              to={`/editor/${id}/integrations`}
            >
              <span className="cp-text-tiny">Integrations</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={cn(styles.nav_item)}
              activeClassName={styles.active_nav}
              to={`/editor/${id}/publish-as`}
            >
              <span className="cp-text-tiny">Publish</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={cn(styles.nav_item)}
              activeClassName={styles.active_nav}
              to={`/editor/${id}/submissions`}
            >
              {' '}
              <span className="cp-text-tiny">Submissions</span>
            </NavLink>
          </li>
        </ul>
      </div> */}
      <div
        className={cn(
          'bg-cp-white border-b border-t border-cp-gray-10 dark:border-cp-gray-0',
          pathname === `editor/${id}/integrations` ||
            pathname === `editor/${id}/webhooks` ||
            pathname.split('/').includes('webhooks')
            ? 'block'
            : 'hidden'
        )}
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <nav>
                {pathname !== '/webhooks' && pathname.split('/').includes('webhooks') ? (
                  <ul className={cn('flex items-center')}>
                    <li>
                      <NavLink
                        className={cn(
                          'py-3 sm:py-6 cp-text-tiny font-medium cursor-pointer text-cp-gray-100 flex items-center'
                        )}
                        to={`/editor/${id}/webhooks`}
                      >
                        <svg width="8" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.94062 7.11107L7.89062 12.0611L6.47662 13.4751L0.112624 7.11107L6.47663 0.74707L7.89062 2.16107L2.94062 7.11107Z"
                            fill="var(--text-gray-100)"
                          />
                        </svg>
                        <span className="block ml-2 leading-none">Back</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  <ul className="flex items-center">
                    <li>
                      <NavLink
                        className={cn(
                          'block py-3 sm:py-6 cp-text-tiny font-medium cursor-pointer text-cp-gray-40',
                          pathname === '/integrations' && ' border-b-2 border-cp-primary-100 text-black'
                        )}
                        to={`/editor/${id}/integrations`}
                      >
                        Integrations
                      </NavLink>
                    </li>
                    <li className="ml-8">
                      <NavLink
                        className={cn(
                          'block py-3 sm:py-6 cp-text-tiny font-medium cursor-pointer text-cp-gray-40',
                          pathname === '/webhooks' && ' border-b-2 border-cp-primary-100 text-black'
                        )}
                        to={`/editor/${id}/webhooks`}
                      >
                        Webhooks
                      </NavLink>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormHeader
