import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { applyPolyfills, defineCustomElements } from '@saypr/cabinpanda-editor/loader'

import './scss/tailwind/before.css'
import './scss/global.scss'
import './scss/tailwind/after.css'

import { store } from './modules/store'
import { Provider } from 'react-redux'
import history from './history'

import App from './App'

require('dotenv').config()
const isSentryEnabled = process.env.NODE_ENV === 'development'

Sentry.init({
  dsn: 'https://3e830503fc934e3fa2305f2e1ddf6ffb@o309777.ingest.sentry.io/5898627',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: !isSentryEnabled,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* basename={`/editor/${window.form_id}`} */}
      <BrowserRouter history={history}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
applyPolyfills().then(() => {
  defineCustomElements(window)
})
