import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  currentTheme: null,
  initial_design: {
    background: {
      color: null,
      image: null,
      repeat: null,
      size: null,
      position: null,
    },
    primary_color: null,
    font: null,
    template_id: null,
  },
  changed_design: {
    background: {
      color: null,
      image: null,
      repeat: null,
      size: null,
      position: null,
    },
    primary_color: null,
    font: null,
    template_id: null,
  },
}

export const layoutSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    setCurrentTheme: (state, action) => {
      const draft = state
      draft.current_theme = action.payload
    },
    setInitialDesign: (state, action) => {
      const draft = state
      draft.initial_design = {
        ...draft.initial_design,
        ...action.payload.design,
      }
    },
    setChangedDesign: (state, action) => {
      const draft = state
      draft.changed_design = {
        ...draft.changed_design,
        ...action.payload.design,
      }
    },
    updateChangedDesign: (state, action) => {
      const { key, value } = action.payload
      const draft = state
      const obj = _.set(draft.changed_design, key, value)
      draft.changed_design = obj
    },
  },

  extraReducers: (builder) => {
    // builder
    //   .addMatcher(workspace.endpoints.login.matchFulfilled, (state, { payload }) => {
    //     state.auth.token = payload.token
    //     state.auth.user = payload.user
    //   })
  },
})

// Action creators are generated for each case reducer function
export const { setInitialDesign, setChangedDesign, updateChangedDesign, currentTheme, setCurrentTheme } =
  layoutSlice.actions

export default layoutSlice.reducer

export const selectScheduling = (state) => state.scheduling

export const selectInitialDesign = createDraftSafeSelector(selectScheduling, (state) => state.initial_design)
export const selectChangedDesign = createDraftSafeSelector(selectScheduling, (state) => state.changed_design)
export const selectCurrentTheme = createDraftSafeSelector(selectScheduling, (state) => state.current_theme)
