import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetFormQuery } from '../../../modules/services/workspaceApi'
import { setFormData, setFormId, resetForm, selectFormData } from '../../../modules/reducers/app'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../Loading'

const Layout = ({ children }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { data: form, isLoading } = useGetFormQuery(id, { skip: !id, refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (form) {
      dispatch(setFormData({ form: form }))
    }
  }, [form])

  useEffect(() => {
    if (id) {
      dispatch(setFormId(id))
    }
  }, [id])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div
      className="layout-children"
      /* className={cn(
        matchPath(location.pathname, { path: '/editor/:id' })
          ? 'layout-without-space'
          : matchPath(location.pathname, { path: '/editor/:id/integrations' }) ||
            matchPath(location.pathname, { path: '/editor/:id/webhooks' }) ||
            location?.pathname.split('/').includes('webhooks')
          ? 'layout-large-space'
          : 'layout-children'
      )} */
    >
      {children}
    </div>
  )
}

export default Layout
