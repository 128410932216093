import React from 'react'
import cn from 'classnames'
import styles from './Loading.module.scss'

function Loading(props) {
  const { className, small = false, text } = props
  return (
    <div>
      <div className={cn(styles.loader, small && styles.small, className)}></div>
      {text && (
        <div className={styles.text}>
          <p>{text}</p>
        </div>
      )}
    </div>
  )
}

export default Loading
