import React from 'react'
import styles from './Progress.module.scss'

const Progress = ({ percentage, color }) => {
  return (
    <div className={styles.progress}>
      <div style={{ width: `${percentage ?? 0}%`, backgroundColor: color }} className={styles.progress_inner}></div>
    </div>
  )
}

export default Progress
