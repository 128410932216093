import React, { useRef, useEffect } from 'react'
import hasParent from './has-parent'

const ClickOutside = ({ active = true, onClick, children }) => {
  const innerRef = useRef()

  const handleClick = (event) => {
    if (!hasParent(event.target, innerRef?.current)) {
      if (typeof onClick === 'function') {
        onClick(event)
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      onClick(event)
    }
  }

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClick)
      document.addEventListener('touchstart', handleClick)
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (active) {
        document.removeEventListener('mousedown', handleClick)
        document.removeEventListener('touchstart', handleClick)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  })

  return React.cloneElement(children, { ref: innerRef })
}

export default ClickOutside
