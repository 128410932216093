import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './DashboardHeader.module.scss'
import Button from '../../Button'
import { useHistory, NavLink } from 'react-router-dom'
import ClickOutside from '../../../../lib/click-outside'
import { useLazyLogoutQuery } from '../../../../modules/services/authApi'
import {
  useGetWorkspacesQuery,
  useGetWorkspaceQuery,
  useChangeWorkspaceMutation,
} from '../../../../modules/services/workspaceApi'
import { setClearAll } from '../../../../modules/reducers/app'
import { useGetUserQuery } from '../../../../modules/services/workspaceApi'

import { useDispatch } from 'react-redux'

// import { forms } from '../../../modules/services/workspaceApi'
// import { integrations } from '../../../modules/services/integrationsApi'
// import { webhooks } from '../../../modules/services/webhooksApi'
// import { recordings } from '../../../modules/services/workspaceApi'
// import { events } from '../../../modules/services/eventsApi'

import alert from 'react-hot-toast'

const DashboardHeader = (props) => {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const [dropdown, setDropdown] = useState(false)
  const [workspaceDropdown, setWorkspaceDropdown] = useState(false)

  const { data: userData } = useGetUserQuery()
  const [updateWorkspace] = useChangeWorkspaceMutation()
  const [trigger, result] = useLazyLogoutQuery()
  const { data: workspaces } = useGetWorkspacesQuery()
  const { data: currentWorkspace } = useGetWorkspaceQuery()

  useEffect(() => {
    if (result?.isSuccess && result?.data && result?.data?.message) {
      dispatch(setClearAll())

      alert(result?.data?.message ?? 'Logout succesfully', {
        type: 'success',
      })
    }
  }, [result])

  return (
    <div className={styles.header}>
      <div className="flex items-center">
        <div className="flex-1">
          <div className="flex items-center justify-start">
            <div className="flex-1 flex relative ml-6">
              <ClickOutside onClick={() => setWorkspaceDropdown(false)}>
                <div>
                  <span
                    className="cursor-pointer flex items-center"
                    onClick={() => {
                      setWorkspaceDropdown(!workspaceDropdown)
                    }}
                  >
                    <img
                      src={currentWorkspace?.image_url}
                      alt="workspace_image"
                      className="rounded-full border w-8 h-8 overflow-hidden"
                    />
                    <span className="cp-text-tiny font-bold ml-2">{currentWorkspace?.name}</span>
                    <i
                      className={cn(
                        'icon-arrow ml-10 transition duration-150 ease-in-out',
                        workspaceDropdown && 'transform rotate-180'
                      )}
                    ></i>
                  </span>

                  <div className={cn(styles.workspace_dropdown, workspaceDropdown ? 'block' : 'hidden')}>
                    <div>
                      <div className="divide-y divide-gray-100">
                        {workspaces &&
                          !!workspaces.length &&
                          workspaces.map((workspace, index) => {
                            if (workspace?.owner_id === userData?.id) {
                              return (
                                <div className={cn('cursor-pointer group')} key={index}>
                                  <a
                                    className="block p-2 cp-text-tiny group-hover:bg-gray-100"
                                    onClick={() =>
                                      updateWorkspace({ id: workspace?.id }).then(() => window.location.reload())
                                    }
                                  >
                                    <div className="cursor-pointer flex items-center space-x-2">
                                      <div className="relative">
                                        <img
                                          src={workspace?.image_url}
                                          className="rounded-full border w-8 h-8 overflow-hidden"
                                          alt="workspace_image"
                                        />
                                        {workspace?.id === currentWorkspace?.id && (
                                          <span className="w-3 h-3 flex items-center bg-cp-primary-100 rounded-full overflow-hidden absolute -right-1 -bottom-1">
                                            <i className="icon-check text-white cp-text-sm"></i>
                                          </span>
                                        )}
                                      </div>
                                      <span className="cp-text-tiny font-bold text-gray-500">{workspace?.name}</span>
                                    </div>
                                  </a>
                                </div>
                              )
                            }

                            return null
                          })}
                      </div>
                      <p className="p-2 cp-text-sm leading-6 text-gray-500 border-t border-cp-gray-10 dark:border-cp-gray-00">
                        Guest Workspaces
                      </p>
                      <div className="divide-y divide-gray-100">
                        {workspaces &&
                          !!workspaces.length &&
                          workspaces.map((workspace, index) => {
                            if (workspace.owner_id !== userData?.id) {
                              return (
                                <div className={cn('cursor-pointer group')} key={index}>
                                  <a
                                    className="block p-2 border-transparent border-l-4 cp-text-tiny group-hover:bg-gray-100"
                                    onClick={() => updateWorkspace({ id: workspace?.id })}
                                  >
                                    <div className="cursor-pointer flex items-center space-x-2">
                                      <div className="relative">
                                        <img
                                          alt="workspace_image"
                                          src={workspace?.image_url}
                                          className="rounded-full border w-8 h-8 overflow-hidden"
                                        />
                                        {workspace?.id === currentWorkspace?.id && (
                                          <span className="w-3 h-3 flex items-center bg-cp-primary-100 rounded-full overflow-hidden absolute -right-1 -bottom-1">
                                            <i className="icon-check text-white cp-text-sm"></i>
                                          </span>
                                        )}
                                      </div>
                                      <span className={cn('cp-text-tiny font-bold text-gray-500')}>
                                        {workspace?.name}
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              )
                            }

                            return null
                          })}
                      </div>
                      <div className="divide-y divide-gray-100">
                        <div className="px-2 py-4 border-t border-cp-gray-10 dark:border-cp-gray-00">
                          <Button className="btn-primary" onClick={() => push('/account/workspaces')}>
                            Create workspace
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ClickOutside>
            </div>
          </div>
        </div>
        <div className="flex-1 hidden lg:block">
          <div className="flex items-center justify-center">
            <ul className="flex space-x-6">
              <li>
                <NavLink exact to="/" className={styles.nav_item} activeClassName={styles.active_nav}>
                  <i className="icon-form"></i>
                  <span className="cp-text-tiny">Forms</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/analytics" className={styles.nav_item} activeClassName={styles.active_nav}>
                  <i className="icon-analytic"></i>
                  <span className="cp-text-tiny">Analytics</span>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/recordings" className={styles.nav_item} activeClassName={styles.active_nav}>
                  <i className="icon-screen-recorder"></i>
                  <span className="cp-text-tiny">Recordings</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/settings" className={styles.nav_item} activeClassName={styles.active_nav}>
                  <i className="icon-settings"></i>
                  <span className="cp-text-tiny">Settings</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-1 flex justify-end relative">
          <ClickOutside onClick={() => setDropdown(false)}>
            <div>
              <span
                className="cursor-pointer flex items-center"
                onClick={() => {
                  setDropdown(!dropdown)
                }}
              >
                <img
                  src={userData?.photo_url}
                  alt="user_image"
                  className="rounded-full border w-8 h-8 overflow-hidden"
                />
                <span className="cp-text-tiny font-bold ml-2">{userData?.name}</span>
                <i
                  className={cn(
                    'icon-arrow ml-10 transition duration-150 ease-in-out',
                    dropdown && 'transform rotate-180'
                  )}
                ></i>
              </span>

              <div className={cn(styles.navbar_dropdown, dropdown ? 'block' : 'hidden')}>
                <div>
                  <div className="cursor-pointer group">
                    <a
                      className="block p-2 border-transparent border-l-4 cp-text-tiny group-hover:bg-gray-100"
                      onClick={() => {
                        setDropdown(false)
                        push('/account/overview')
                      }}
                    >
                      <i className="icon-settings text-gray-600 mr-1"></i> Account settings
                    </a>
                  </div>
                  <div className="cursor-pointer group border-t border-cp-gray-10 dark:border-cp-gray-0">
                    <a
                      className="block p-2 border-transparent border-l-4 cp-text-tiny group-hover:bg-gray-100"
                      onClick={() => {
                        setDropdown(false)
                        push('/billing/subscription')
                      }}
                    >
                      <i className="icon-build text-gray-600 mr-1"></i> Billing
                    </a>
                  </div>
                  <div className="cursor-pointer group border-t border-cp-gray-10 dark:border-cp-gray-0">
                    <a
                      className="block p-2 border-transparent border-l-4 cp-text-tiny group-hover:bg-gray-100"
                      onClick={() => trigger()}
                    >
                      <i className="icon-log-out text-gray-600 mr-1"></i> Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ClickOutside>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
