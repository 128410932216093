import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import ClickOutside from '../../../../lib/click-outside'
import Notifications from '../Notifications'
// import ThemeButton from '../../ThemeButton'

import { useLazyLogoutQuery } from '../../../../modules/services/authApi'
import { useGetWorkspaceQuery, useGetUserQuery } from '../../../../modules/services/workspaceApi'
import { setClearAll } from '../../../../modules/reducers/app'
import { useGetNotificationsQuery } from '../../../../modules/services/notificationsApi'

import { useDispatch } from 'react-redux'

import styles from '../AppRender.module.scss'

import alert from 'react-hot-toast'
import { Link } from 'react-router-dom'

const Header = ({ setToggleMobile, toggleMobile, currentRoute }) => {
  const dispatch = useDispatch()

  const [dropdown, setDropdown] = useState(false)
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const { data: userData } = useGetUserQuery()
  const [trigger, result] = useLazyLogoutQuery()
  const { data: currentWorkspace } = useGetWorkspaceQuery()
  const { data: notifications } = useGetNotificationsQuery()

  useEffect(() => {
    if (result?.isSuccess && result?.data && result?.data?.message) {
      dispatch(setClearAll())

      alert(result?.data?.message ?? 'Logout succesfully', {
        type: 'success',
      })
    }
  }, [result])

  return (
    <header className={styles.app_header}>
      <div className="container">
        <div className="flex items-center">
          <div className={styles.header_toggler} onClick={() => setToggleMobile(!toggleMobile)}>
            <span className={styles.icon}></span>
          </div>
          <div className={styles.breadcrumb}>
            <div className="flex-1">
              <div className="space-x-2 flex items-center">
                <span className="w-3 h-3 rounded-full bg-cp-gray-10" />
                <span className="w-3 h-3 rounded-full bg-cp-gray-60" />
                <ul className="flex items-center space-x-2">
                  <li className="flex">
                    <span className="badged badged-small">{currentWorkspace?.name}</span>
                  </li>
                  <li>
                    <i className="block icon-arrow transform -rotate-90"></i>
                  </li>
                  {currentRoute?.sidebar?.name && (
                    <li className="flex items-baseline">
                      <p className="small text-cp-gray-40">{currentRoute?.sidebar?.name}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.header_logo}>
            <Link to="/" className="transition duration-300 block">
              <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.8498 3.80668C11.901 3.80668 12.7532 2.95453 12.7532 1.90334C12.7532 0.852155 11.901 0 10.8498 0C9.79863 0 8.94647 0.852155 8.94647 1.90334C8.94647 2.95453 9.79863 3.80668 10.8498 3.80668Z"
                  fill="var(--text-gray-100)"
                />
                <path
                  d="M29.1911 3.80668C30.2423 3.80668 31.0945 2.95453 31.0945 1.90334C31.0945 0.852155 30.2423 0 29.1911 0C28.1399 0 27.2878 0.852155 27.2878 1.90334C27.2878 2.95453 28.1399 3.80668 29.1911 3.80668Z"
                  fill="var(--text-gray-100)"
                />
                <path
                  d="M2 13.3675C2 19.8582 7.26269 26.1006 13.7463 26.1006C16.0531 26.1006 18.2047 25.5516 20.0203 24.5517C23.3069 22.7422 25.49 19.4565 25.4918 15.2788C25.4839 11.8914 23.0473 9.14862 20.0414 9.14862C17.0355 9.14862 14.5559 11.8931 14.5489 15.2832C14.5489 19.4582 16.7347 22.743 20.0203 24.5517C21.836 25.5516 23.9875 26.1006 26.2944 26.1006C32.785 26.1006 38.0407 19.8477 38.0407 13.3605"
                  stroke="var(--text-gray-100)"
                  strokeWidth="2.19279"
                  strokeMiterlimit="10"
                />
              </svg>
            </Link>
          </div>
          <div className="flex items-center relative flex-1 justify-end">
            <div className={styles.profile}>
              {/* <ThemeButton /> */}
              <ClickOutside onClick={() => setNotificationsOpen(false)}>
                <span className="flex items-center mr-6 relative">
                  <div
                    className="cursor-pointer flex items-center"
                    onClick={() => setNotificationsOpen(!notificationsOpen)}
                  >
                    <i className="icon-notificaiton"></i>
                    <span className="absolute -top-2 -bottom-0 -right-2 text-xs font-bold w-4 h-4 rounded-full overflow-hidden bg-cp-primary-100 text-white flex items-center justify-center">
                      {notifications?.count ?? 0}
                    </span>
                  </div>

                  <Notifications
                    notifications={notifications}
                    isOpen={notificationsOpen}
                    setNotificationsOpen={() => setNotificationsOpen()}
                  />
                </span>
              </ClickOutside>

              <ClickOutside onClick={() => setDropdown(false)}>
                <div>
                  <div
                    className="cursor-pointer flex items-center"
                    onClick={() => {
                      setDropdown(!dropdown)
                    }}
                  >
                    <img src={userData?.photo_url} className={styles.avatar} alt="avatar" />
                  </div>

                  <div className={cn(styles.navbar_dropdown, dropdown ? 'absolute' : 'hidden')}>
                    <ul className={styles.dropdown_list}>
                      <li>
                        <p className="small font-bold">{userData?.name}</p>
                      </li>
                    </ul>
                    <ul className={styles.dropdown_list}>
                      {userData?.show_kiosk && (
                        <li className="flex cursor-pointer">
                          <Link to="/kiosk/users" className="small">
                            Kiosk
                          </Link>
                        </li>
                      )}
                      <li className="flex cursor-pointer">
                        <a href="mailto:support@cabinpanda.com" rel="noreferrer" className="small">
                          Support
                        </a>
                      </li>
                      <li className="cursor-pointer" onClick={() => window?.$crisp?.push(['do', 'chat:open'])}>
                        <p className="small">Live chat</p>
                      </li>
                      <li className="cursor-pointer" onClick={() => trigger()}>
                        <p className="small">Log out</p>
                      </li>
                    </ul>
                    <ul className={styles.dropdown_list_small}>
                      <li className="cursor-pointer">
                        <a
                          href="https://apps.apple.com/us/app/cabinpanda/id1579677033?ign-mpt=uo%3D2"
                          target="_blank"
                          rel="noreferrer"
                          className="small text-cp-gray-60"
                        >
                          Download iOS app
                        </a>
                      </li>
                      <li className="cursor-pointer">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.saypr.cabinpanda"
                          target="_blank"
                          rel="noreferrer"
                          className="small text-cp-gray-60"
                        >
                          Download Android app
                        </a>
                      </li>
                    </ul>
                    <ul className={styles.dropdown_list_small}>
                      {/* <li className="cursor-pointer" onClick={() => setLearnOpen(true)}>
                        <p className="small text-cp-gray-60">Learn</p>
                      </li> */}
                      <li className="cursor-pointer">
                        <a
                          href="https://www.cabinpanda.com/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                          className="small text-cp-gray-60"
                        >
                          Privacy policy
                        </a>
                      </li>
                      <li className="cursor-pointer">
                        <a
                          href="https://www.cabinpanda.com/terms-and-conditions"
                          target="_blank"
                          rel="noreferrer"
                          className="small text-cp-gray-60"
                        >
                          Terms and conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </ClickOutside>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
