import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ClickOutside from '../../../lib/click-outside'

import styles from './FastLink.module.scss'
import cn from 'classnames'

const FastLink = ({ formTypes }) => {
  const [isOpen, setOpen] = useState(false)

  const types = {
    'regular-form': {
      name: 'Regular',
      icon: 'icon-form',
    },
    'backendless-form': {
      name: 'Backendless',
      icon: 'icon-form-backend',
    },
    'popup-form': {
      name: 'Popup',
      icon: 'icon-popup',
    },
    'conversational-form': {
      name: 'Conversational',
      icon: 'icon-submission',
    },
  }
  return (
    <ClickOutside onClick={() => setOpen(false)}>
      <div className={styles['create-new-form']}>
        <div className={cn(styles['button'], { [styles['active']]: isOpen })} onClick={() => setOpen(!isOpen)}>
          <i className="icon-add" />
        </div>
        {isOpen && (
          <div className={styles['create-new-form-box']}>
            <div className="mb-5">
              <p className="cp-text-xs text-cp-gray-40 flex items-center mb-1">
                <i className="icon-form mr-1" />
                FORM TYPES
              </p>
              <div className="flex flex-wrap md:flex-nowrap">
                {formTypes.map((type, i) => (
                  <Link key={i} to={`/create-form/${type.key}`} className={styles['form-type']}>
                    <div className={styles['form-type-box']}>
                      <i className={types[type.key].icon} />
                    </div>
                    <span className="mt-1 xsmall">{types[type.key].name}</span>
                  </Link>
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <p className="cp-text-xs text-cp-gray-40 flex items-center mb-1">
                  <i className="icon-date mr-1" />
                  SCHEDULING
                </p>
                <div className="flex">
                  <Link to="/create-form/scheduling-form?type=multi-use" className={styles['form-type']}>
                    <div className={styles['form-type-box']}>
                      <i className="icon-calendar-multi" />
                    </div>
                    <span className="mt-1 xsmall">Multi Use</span>
                  </Link>
                  <Link to="/create-form/scheduling-form?type=single-use" className={styles['form-type']}>
                    <div className={styles['form-type-box']}>
                      <i className="icon-calendar" />
                    </div>
                    <span className="mt-1 xsmall">Single Use</span>
                  </Link>
                </div>
              </div>
              {/* <div>
              <p className="small text-cp-gray-40 flex items-center mb-1">
                <i className="icon-screen-recorder mr-1" />
                RECORDING
              </p>
              <div className="flex">
                <div className={styles['form-type']}>
                  <div className={styles['form-type-box']}>
                    <i className="icon-form" />
                  </div>
                  <span className="mt-1 small">Add website</span>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </ClickOutside>
  )
}

export default FastLink
