import React, { useRef, useState } from 'react'
import Button from '../Button'
import cn from 'classnames'
import styles from './copyInput.module.scss'

const CopyInput = (props) => {
  const { link, blank = true } = props
  const [copyText, setCopyText] = useState('Copy')
  const inputRef = useRef()

  function copy() {
    if (!inputRef.current) return false

    inputRef.current.select()
    document.execCommand('copy')
    if (copyText === 'Copy') {
      setCopyText('Copied!')
    }
  }

  return (
    <div className={cn('flex items-center border border-cp-gray-20 w-full', styles.copy_input)}>
      <input
        className="cp-text-tiny text-cp-gray-100 pl-2 pr-4 outline-none w-full"
        ref={inputRef}
        type="text"
        value={link}
      />
      {blank && (
        <a
          className="flex h-full items-center justify-center border-l border-cp-gray-20 text-cp-gray-40 hover:text-cp-gray-100"
          target="_blank"
          rel="noreferrer"
          href={link}
        >
          <span className="px-2">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 3V5H5V19H19V14H21V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H10ZM17.707 7.707L12 13.414L10.586 12L16.293 6.293L13 3H21V11L17.707 7.707Z"
                fill="var(--text-gray-100)"
              />
            </svg>
          </span>
        </a>
      )}

      <Button onClick={copy} className="h-full">
        <svg
          viewBox="0 0 24 24"
          width="22"
          height="22"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          shapeRendering="geometricPrecision"
          className="text-cp-gray-40 hover:text-cp-gray-100"
        >
          <path d="M8 17.929H6c-1.105 0-2-.912-2-2.036V5.036C4 3.91 4.895 3 6 3h8c1.105 0 2 .911 2 2.036v1.866m-6 .17h8c1.105 0 2 .91 2 2.035v10.857C20 21.09 19.105 22 18 22h-8c-1.105 0-2-.911-2-2.036V9.107c0-1.124.895-2.036 2-2.036z"></path>
        </svg>
      </Button>
    </div>
  )
}

export default CopyInput
