import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
import { routes, authRoutes } from './route'

import Loading from './components/Common/Loading'
import AppRender from './components/Common/AppRender'

import Alert from './components/Common/Alert'
import { Toaster } from 'react-hot-toast'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { setAuth, selectAuth } from './modules/reducers/app'
import { useGetWhiteLabelQuery } from './modules/services/workspaceApi.js'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, matchPath } from 'react-router-dom'

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3000,
  transition: transitions.SCALE,
}

const NonAuthmiddleware = ({ component, path }) => {
  const history = useHistory()
  const Component = component
  const { token } = useSelector(selectAuth)

  if (token && !history?.location?.pathname?.includes('/auth/')) {
    //  && !history?.location?.pathname?.includes('/event/cancel/')
    if (history?.location?.state?.from?.pathname) {
      return (
        <Redirect
          to={{ pathname: history?.location?.state?.from?.pathname, search: history?.location?.state?.from?.search }}
        />
      )
    }
    return <Redirect to={{ pathname: '/forms/regular-form' }} />
  }

  return (
    <Route
      path={path}
      render={(props) => {
        return <Component {...props} />
      }}
    />
  )
}

const App = () => {
  const isDarkTheme = localStorage.getItem('CP_DARKMODE')
  if (isDarkTheme) {
    document.querySelector('html').classList.add('dark')
  }

  const token = window.localStorage.getItem('CP_TOKEN')
  const dispatch = useDispatch()
  let location = useLocation()

  const { data, isFetching } = useGetWhiteLabelQuery({
    params: {
      // url: 'https://ayberkersoy.com', // test url
      url: window.location.origin,
    },
  })

  if (isFetching) return null

  if (token) {
    dispatch(
      setAuth({
        token,
      })
    )
  }

  const currentRoute = routes.find((route) => matchPath(location.pathname, { path: route.path, exact: route.exact }))

  return (
    <AlertProvider template={Alert} {...options}>
      <Helmet>
        {data?.image_url && <link rel="icon" type="image/png" href={data?.image_url} />}
        {data?.name && <title>{data?.name}</title>}
      </Helmet>
      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="toast_alert_container"
        toastOptions={{
          // Define default options
          className: 'toast_alert',
          duration: 3000,
          // Default options for specific types
          success: {
            duration: 3000,
            icon: false,
            className: 'toast_alert_success',
          },
          error: {
            duration: 3000,
            icon: false,
            className: 'toast_alert_error',
          },
        }}
      />

      {/* <Header /> */}
      {/* {!noHeader ? isSubHeader ? <Header /> : <DashboardHeader /> : null} */}
      <Suspense fallback={<Loading />}>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware path={route.path} component={route.component} key={idx} token={token} />
          ))}
          <AppRender routes={routes} currentRoute={currentRoute} />
        </Switch>
      </Suspense>

      {/* {!data?.name && !noHeader && !isSubHeader && <Footer />} */}
    </AlertProvider>
  )
}

export default App
