import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

// import { forms } from '../services/formsApi'
import { integrations } from '../services/integrationsApi'
import { webhooks } from '../services/webhooksApi'
import { workspace } from '../services/workspaceApi'
import { events } from '../services/eventsApi'
import { auth } from '../services/authApi'
import { user } from '../services/userApi'

const initialState = {
  whitelabel: {
    name: null,
    image_url: null,
  },
  auth: {
    user: null,
    token: null,
  },
  form: {
    formId: null,
    isDirty: false,
    data: null,
    saved: false,
  },
}

export const layoutSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetForm: (state) => {
      const draft = state
      draft.form = {
        formId: null,
        isDirty: false,
        data: null,
        saved: false,
      }
    },
    setAuth: (state, action) => {
      const draft = state
      draft.auth.token = action.payload.token
      draft.auth.user = action.payload.user
    },
    setFormId: (state, action) => {
      const form = state.form
      form.formId = action.payload
    },
    setDirty: (state, action) => {
      const form = state.form
      form.isDirty = action.payload
    },
    setFormData: (state, action) => {
      const form = state.form
      form.data = action.payload.form
    },
    setFormName: (state, action) => {
      const form = state.form
      form.data.name = action.payload
      form.isDirty = true
    },
    setSaved: (state, action) => {
      const form = state.form
      form.saved = action.payload
    },
    setFormType: (state, action) => {
      const form = state.form
      form.data.detail.type = action.payload
    },
    setClearAll: (state) => {
      state.auth = {
        user: null,
        token: null,
      }
      state.form = {
        formId: null,
        isDirty: false,
        data: {},
        saved: false,
      }
      // forms.util.resetApiState()
      integrations.util.resetApiState()
      webhooks.util.resetApiState()
      workspace.util.resetApiState()
      events.util.resetApiState()
      auth.util.resetApiState()
      user.util.resetApiState()
      // settings.util.resetApiState()
      window.localStorage.removeItem('CP_TOKEN')
      window.localStorage.removeItem('CP_PREV_TOKEN')
      window.localStorage.removeItem('CP_IMPERSONATE_DEFAULT_USER')
      // window.location.href('/login')
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(workspace.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.auth.token = payload.token
        state.auth.user = payload.user
      })
      .addMatcher(workspace.endpoints.getWhiteLabel.matchFulfilled, (state, { payload }) => {
        state.whitelabel.name = payload?.name ?? null
        state.whitelabel.image_url = payload?.image_url ?? null
      })
      .addMatcher(workspace.endpoints.emailVerification.matchFulfilled, (state, { payload }) => {
        state.auth.token = payload.token
        state.auth.user = payload.user
      })
      .addMatcher(workspace.endpoints.getUser.matchFulfilled, (state, { payload }) => {
        localStorage.setItem('SETUP_COMPLETE', payload?.is_setup_complete)
        if (window?.$crisp && payload?.email && payload?.current_billing_plan) {
          window?.$crisp?.push(['set', 'user:email', [payload?.email]])
          window?.$crisp?.push(['set', 'session:data', [[['plan', payload?.current_billing_plan]]]])
          window?.$crisp.push(['do', 'chat:show'])
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { resetForm, setClearAll, setAuth, setFormId, setDirty, setFormData, setFormName, setSaved, setFormType } =
  layoutSlice.actions

export default layoutSlice.reducer

export const selectForm = (state) => state.app.form
export const selectAuth = (state) => state.app.auth
export const selectWhitelabel = (state) => state.app.whitelabel

export const selectFormId = createDraftSafeSelector(selectForm, (state) => state.formId)

export const selectIsDirty = createDraftSafeSelector(selectForm, (state) => state.isDirty)

export const selectFormData = createDraftSafeSelector(selectForm, (state) => state?.data)

export const selectFormName = createDraftSafeSelector(selectForm, (state) => state?.data?.name)

export const selectSavedStatus = createDraftSafeSelector(selectForm, (state) => state.saved)

export const selectFormType = createDraftSafeSelector(selectForm, (state) => state?.data?.detail?.type)
