import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import onboardingENG from "./locales/eng/onboarding.json";
import onboardingTR from "./locales/tr/onboarding.json";

// the translations
const resources = {
  eng: {
    onboarding: onboardingENG,
  },
  tr: {
    onboarding: onboardingTR,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ["onboarding"],
    defaultNS: "onboarding",
    fallbackLng: "eng",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

export default i18n;
