import React from 'react'
import Select from 'react-select'

const customSelectStyles = (large) => {
  return {
    container: (provided, state) => ({
      ...provided,
      pointerEvents: state.isDisabled ? null : null,
      // flex: 1,
      padding: '6px 4px',
      border: '1px solid var(--bg-gray-20)',
      borderRadius: '3px',
      ///
      height: large ? '40px' : '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: state.isDisabled ? '#FBFBFB' : 'var(--bg-white)',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      '@media only screen and (max-width: 868px)': {
        height: '32px',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      paddingLeft: '4px',
      paddingRight: '4px',
      fontSize: '13px',
      minHeight: '0px',
      lineHeight: '16px',
      width: '100%',
      color: 'var(--gray-100)',
      backgroundColor: state.isDisabled ? '#FBFBFB' : 'var(--bg-white)',
    }),
    singleValue: (provided) => ({
      ...provided,
      width: '100%',
      color: 'var(--gray-100)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      // height: '20px',
      // input: {
      //   height: '1px',
      // },
    }),
    // input: (provided, state) => ({
    //   ...provided,
    //   height: '19px',
    //   overflow: 'hidden',
    // }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      lineHeight: '16px',
    }),
    option: (provided, isSelected) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',

      '&:hover': {
        backgroundColor: 'var(--gray-10)',
        cursor: 'pointer',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#7D7E7E',
      padding: '0px',
      svg: {
        width: '16px',
        height: '16px',
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
      border: '1px solid var(--bg-gray-20)',
      boxShadow: '0px 10px 30px rgba(34, 34, 34, 0.05)',
      borderRadius: '3px',
      fontSize: '13px',
      lineHeight: '16px',
      margin: '0px',
      marginTop: '2px',
      marginLeft: '-2px',
      backgroundColor: 'var(--bg-gray-0)',
    }),
  }
}

const SelectField = (props) => {
  const {
    className,
    icon = null,
    large = true,
    onChange,
    onChangeCallback,
    options,
    field,
    form,
    label,
    value,
    onBlur,
    disabled,
    ...args
  } = props
  const hasAlert = form?.errors?.[field?.name] && form?.touched?.[field?.name]

  return (
    <label className="input">
      {label && (
        <div className="flex items-center space-x-1 mb-1">
          {icon && icon}
          <p className="font-medium text-cp-gray-100">{label}</p>
        </div>
      )}
      <Select
        className={className}
        isDisabled={disabled}
        styles={customSelectStyles(large)}
        options={options}
        name={field?.name}
        value={args.isMulti ? value : options ? options.find((option) => option?.value === field?.value) : ''}
        onChange={(option) => {
          if (onChange) onChange(option)
          form?.setFieldValue(field?.name, args.isMulti ? option : option?.value)
          onChangeCallback && onChangeCallback(field?.value !== option?.value)
        }}
        onBlur={onBlur ?? field?.onBlur}
        isSearchable={false}
        // formatOptionLabel={(props, obj) => {
        //   return <p className="truncate text-cp-gray-100">{props?.children}</p>
        // }}
        {...args}
      />
      {hasAlert && (
        <p className="small font-medium text-cp-alert-100 flex items-center mt-1">
          <i className="icon-tooltip mr-1" />
          {form?.errors[field?.name]}
        </p>
      )}
    </label>
  )
}

export default React.memo(SelectField)
