import React from 'react'
import styles from './alert.module.scss'
import cn from 'classnames'

function Alert(props) {
  return (
    <div className="">
      <div
        className={cn(styles.alert, {
          [styles.error]: props.options.type === 'error',
          [styles.success]: props.options.type === 'success',
          [styles.info]: props.options.type === 'info',
          [styles.primary]: props.options.type === 'primary',
        })}
      >
        <p className="cp-text-tiny text-white">{props.message}</p>
      </div>
    </div>
  )
}

export default Alert
