import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { NavLink, Link, useHistory } from 'react-router-dom'
import ClickOutside from '../../../../lib/click-outside'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'

import {
  useGetWorkspacesQuery,
  useGetWorkspaceQuery,
  useChangeWorkspaceMutation,
  useWorkspaceInviteMutation,
  useGetUserQuery,
  useGetSetupQuery,
} from '../../../../modules/services/workspaceApi'

import Button from '../../Button'
import Input from '../../Input'
import Progress from '../../Progress'
import Loading from '../../Loading'
import Tabs, { Tab } from '../../Tabs'
import { default as CustomSelect } from '../../Select'

import styles from '../AppRender.module.scss'

import alert from 'react-hot-toast'
import Select, { components } from 'react-select'
import customStyles from '../customStyles'
import * as dayjs from 'dayjs'
import { list } from 'postcss'

const Schema = Yup.object().shape({
  email: Yup.string().email().required('Please enter email address'),
})

const options = [
  { value: 'member', label: 'Member' },
  { value: 'owner', label: 'Owner' },
]

Modal.setAppElement('#root')

const SelectMenuButton = (props) => {
  const { push } = useHistory()
  return (
    <components.MenuList {...props}>
      {props.children}
      <button
        className="small text-cp-gray-100 font-medium w-full flex justify-center pb-1"
        onClick={() => push('/settings/account/workspaces?create_modal_status=open')}
      >
        Create New Workspace
      </button>
    </components.MenuList>
  )
}

const statusTypes = {
  good: {
    text: 'All good',
    textColor: '#27ae60',
    color: '#27ae60',
  },
  warning: {
    text: 'Warning',
    textColor: '#F2B02F',
    color: '#F2B02F',
  },
  attention: {
    text: 'Needs attention',
    textColor: '#DE1C22',
    color: '#DE1C22',
  },
}

const Sidebar = ({ setToggleMobile, toggleMobile, mini, setMini, routes, currentRoute }) => {
  const { push } = useHistory()
  const [opened, setOpen] = useState(false)

  const [limits, setLimits] = useState({
    owner: null,
    useges: [],
    status: null,
    resetDate: null,
  })

  const kFormatter = function (num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
      : Math.sign(num) * Math.abs(num)
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [dropdownData, setDropdownData] = useState(null)

  const { data: userData } = useGetUserQuery()
  const [workspaceInvite, { isLoading: isLoadingInvite }] = useWorkspaceInviteMutation()
  const [updateWorkspace] = useChangeWorkspaceMutation()
  const { data: workspaces } = useGetWorkspacesQuery()
  const { data: setup } = useGetSetupQuery(
    {},
    {
      skip: !localStorage.getItem('SETUP_COMPLETE'),
    }
  )
  const { data: currentWorkspace, isFetching: isLoadingCurrentWorkspace } = useGetWorkspaceQuery()

  useEffect(() => {
    if (workspaces && !!workspaces.length) {
      const data = workspaces.reduce(
        (acc, curr) => {
          if (curr?.is_personal === 1) {
            acc['personal'].push(curr)
          }
          if (curr?.owner_id === userData?.id && curr?.is_personal !== 1) {
            acc['yours'].push(curr)
          } else if (curr?.owner_id !== userData?.id) {
            acc['guest'].push(curr)
          }
          return acc
        },
        {
          personal: [],
          yours: [],
          guest: [],
        }
      )
      setDropdownData([
        {
          label: 'Personal Workspace',
          options: data['personal'],
        },
        {
          label: 'Your Workspaces',
          options: data['yours'],
        },
        {
          label: 'Guest Workspaces',
          options: data['guest'],
        },
      ])
    }
  }, [workspaces])

  useEffect(() => {
    if (userData) {
      const usages = [
        {
          title: 'Members',
          percentage: `${(userData?.usages?.members * 100) / userData?.usages?.member_limit}`,
          limit: userData?.usages?.member_limit,
          current: userData?.usages?.members,
          color:
            `${(userData?.usages?.members * 100) / userData?.usages?.member_limit}` >= 80
              ? '#DE1C22'
              : `${(userData?.usages?.members * 100) / userData?.usages?.member_limit}` >= 50
              ? '#F2B02F'
              : '#27ae60',
        },
        {
          title: 'Responses',
          percentage: `${(userData?.usages?.responses * 100) / userData?.usages?.response_limit}`,
          // limit: userData?.usages?.response_limit
          limit: kFormatter(userData?.usages?.response_limit),
          current: userData?.usages?.responses,
          color:
            `${(userData?.usages?.responses * 100) / userData?.usages?.response_limit}` >= 80
              ? '#DE1C22'
              : `${(userData?.usages?.responses * 100) / userData?.usages?.response_limit}` >= 50
              ? '#F2B02F'
              : '#27ae60',
        },
        {
          title: 'Sessions',
          percentage: `${(userData?.usages?.sessions * 100) / userData?.usages?.session_limit}`,
          // limit: userData?.usages?.session_limit,
          limit: kFormatter(userData?.usages?.session_limit),
          current: userData?.usages?.sessions,
          color:
            `${(userData?.usages?.sessions * 100) / userData?.usages?.session_limit}` >= 80
              ? '#DE1C22'
              : `${(userData?.usages?.sessions * 100) / userData?.usages?.session_limit}` >= 50
              ? '#F2B02F'
              : '#27ae60',
        },
      ]

      const max = usages.reduce((prev, current) => {
        return prev > parseInt(current.percentage) ? prev : current.percentage
      }, 0)

      const status = max >= 80 ? statusTypes['attention'] : max >= 50 ? statusTypes['warning'] : statusTypes['good']

      setLimits({
        ...limits,
        owner: userData?.name,
        resetDate: userData?.usages?.renews_at,
        useges: usages,
        status: status,
      })
    }
  }, [userData])

  const formatOptionLabel = (props) => (
    <div className="flex items-center">
      <div className="w-8 h-8 overflow-hidden rounded-full border border-cp-gray-10 dark:border-cp-gray-0 relative flex items-center justify-center">
        <img className="absolute" src={props?.image_url} alt="profile" />
      </div>
      <p className="flex-1 ml-2 font-medium truncate text-cp-gray-100">{props?.name}</p>
    </div>
  )

  const {
    location: { pathname },
  } = useHistory()

  const activeCurrentTab = currentRoute?.sub_sidebar?.menu.find((item) =>
    item?.list?.find((list) => list.to === pathname)
  )

  return (
    <>
      <aside className={styles.app_aside}>
        <div
          className={cn(
            styles.sidebar,
            mini && styles.closed,
            'transition duration-300 antialiased flex flex-col h-screen relative'
          )}
        >
          <div className={cn(styles.logo, 'mb-4 px-1')}>
            <Link to="/" className="transition duration-300 block">
              <svg width="144" height="28" viewBox="0 0 144 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.8498 3.80668C11.901 3.80668 12.7532 2.95453 12.7532 1.90334C12.7532 0.852155 11.901 0 10.8498 0C9.79863 0 8.94647 0.852155 8.94647 1.90334C8.94647 2.95453 9.79863 3.80668 10.8498 3.80668Z"
                  fill="var(--text-gray-100)"
                />
                <path
                  d="M29.1911 3.80668C30.2423 3.80668 31.0945 2.95453 31.0945 1.90334C31.0945 0.852155 30.2423 0 29.1911 0C28.1399 0 27.2878 0.852155 27.2878 1.90334C27.2878 2.95453 28.1399 3.80668 29.1911 3.80668Z"
                  fill="var(--text-gray-100)"
                />
                <path
                  d="M2 13.3675C2 19.8582 7.26269 26.1006 13.7463 26.1006C16.0531 26.1006 18.2047 25.5516 20.0203 24.5517C23.3069 22.7422 25.49 19.4565 25.4918 15.2788C25.4839 11.8914 23.0473 9.14862 20.0414 9.14862C17.0355 9.14862 14.5559 11.8931 14.5489 15.2832C14.5489 19.4582 16.7347 22.743 20.0203 24.5517C21.836 25.5516 23.9875 26.1006 26.2944 26.1006C32.785 26.1006 38.0407 19.8477 38.0407 13.3605"
                  stroke="var(--text-gray-100)"
                  strokeWidth="2.19279"
                  strokeMiterlimit="10"
                />
                <path
                  d="M50.4741 19.0863C50.2975 19.4535 50.0117 19.7572 49.6559 19.9557C49.2747 20.1685 48.8445 20.2778 48.408 20.2728C48.089 20.2823 47.7716 20.2254 47.4758 20.1056C47.18 19.9859 46.9123 19.806 46.6897 19.5773C46.4638 19.3467 46.2868 19.073 46.169 18.7725C46.0513 18.472 45.9953 18.1509 46.0044 17.8283C46.0044 17.1123 46.2294 16.5191 46.6897 16.0588C46.9141 15.8332 47.1823 15.656 47.4779 15.5381C47.7734 15.4203 48.09 15.3642 48.408 15.3735C48.6539 15.371 48.8986 15.4055 49.1342 15.4758C49.4193 15.5598 49.6843 15.7011 49.9129 15.8911C50.1415 16.081 50.329 16.3156 50.4639 16.5804L52.4584 15.4144C52.088 14.7051 51.5194 14.1188 50.8219 13.7268C50.2105 13.3691 49.524 13.1589 48.8172 13.1131C48.6944 13.1028 48.5615 13.1028 48.4387 13.1028H48.3364C47.0272 13.1233 45.9328 13.5836 45.0634 14.4632C44.1633 15.3735 43.7031 16.4986 43.7031 17.8385C43.7031 19.1784 44.1531 20.3035 45.0634 21.2138C45.9737 22.1241 47.0886 22.5741 48.4489 22.5741C49.2856 22.5826 50.1089 22.3649 50.8321 21.9441C51.533 21.5399 52.1097 20.9512 52.4993 20.2421L50.4741 19.0863Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M60.3853 13.3377H62.6969V22.3078H60.3853V21.2441C59.6898 22.1135 58.7181 22.5533 57.46 22.5533C56.2634 22.5533 55.2405 22.093 54.3814 21.1827C53.5222 20.2724 53.1028 19.1473 53.1028 17.8176C53.1028 16.488 53.5324 15.3731 54.3814 14.4525C55.2303 13.532 56.2634 13.082 57.46 13.082C58.7181 13.082 59.6898 13.5218 60.3853 14.3912V13.3377ZM56.1099 19.6485C56.3427 19.8813 56.6207 20.064 56.9268 20.1854C57.2329 20.3068 57.5605 20.3642 57.8896 20.3542C58.2201 20.3632 58.549 20.3053 58.8566 20.184C59.1642 20.0627 59.4441 19.8806 59.6796 19.6485C60.1501 19.178 60.3853 18.5643 60.3853 17.8278C60.3853 17.0914 60.1501 16.4777 59.6796 16.0072C59.2091 15.5367 58.6158 15.3015 57.8896 15.3015C57.1634 15.3015 56.5804 15.5367 56.1099 16.0072C55.6394 16.4777 55.4144 17.0914 55.4144 17.8278C55.4144 18.5643 55.6497 19.1821 56.1099 19.6485Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M70.0197 13.0923C71.2266 13.0923 72.2597 13.5525 73.1189 14.4628C73.978 15.3731 74.3974 16.4982 74.3974 17.8279C74.3974 19.1576 73.9678 20.2724 73.1189 21.193C72.7313 21.631 72.2538 21.9802 71.719 22.2167C71.1841 22.4533 70.6045 22.5716 70.0197 22.5636C68.7616 22.5636 67.79 22.1237 67.1149 21.2544V22.3181H64.8033V9.74765H67.1149V14.3912C67.79 13.5218 68.7616 13.0923 70.0197 13.0923ZM67.8104 19.6485C68.0432 19.8814 68.3212 20.0641 68.6273 20.1855C68.9334 20.3068 69.261 20.3643 69.5901 20.3543C69.9206 20.3632 70.2495 20.3053 70.5571 20.1841C70.8647 20.0628 71.1446 19.8806 71.3801 19.6485C71.8506 19.178 72.0858 18.5643 72.0858 17.8279C72.0858 17.0915 71.8506 16.4778 71.3801 16.0073C70.9096 15.5368 70.3163 15.3016 69.5901 15.3016C68.8639 15.3016 68.2809 15.5368 67.8104 16.0073C67.3399 16.4778 67.1149 17.0915 67.1149 17.8279C67.1149 18.5643 67.3399 19.178 67.8104 19.6485Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M77.1802 12.2638C76.8075 12.2586 76.4516 12.1082 76.188 11.8446C75.9244 11.581 75.774 11.2251 75.7687 10.8524C75.7689 10.6659 75.806 10.4812 75.878 10.3092C75.95 10.1371 76.0554 9.98105 76.1881 9.85C76.4413 9.58438 76.7896 9.43012 77.1565 9.4211C77.5233 9.41209 77.8788 9.54906 78.1447 9.80193L78.1928 9.85C78.3255 9.98105 78.4309 10.1371 78.5029 10.3092C78.5749 10.4812 78.612 10.6659 78.6122 10.8524C78.613 11.0375 78.5762 11.2209 78.5041 11.3914C78.432 11.5619 78.3261 11.716 78.1928 11.8445C78.0611 11.9791 77.9035 12.0857 77.7294 12.1578C77.5554 12.2298 77.3686 12.2659 77.1802 12.2638V12.2638Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M78.3458 13.3383H76.0342V22.3085H78.3458V13.3383Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M85.455 13.0928C86.4369 13.0928 87.245 13.4201 87.8893 14.0747C88.5337 14.7293 88.8508 15.6396 88.8508 16.8056V22.3186H86.5392V17.092C86.5392 16.4988 86.3756 16.0385 86.0585 15.7214C85.7414 15.4043 85.3016 15.2509 84.7697 15.2509C84.1663 15.2509 83.6958 15.435 83.3378 15.8032C82.9798 16.1715 82.7957 16.734 82.7957 17.4704V22.3186H80.4841V13.3526H82.7957V14.3549C83.3378 13.5019 84.2276 13.0928 85.455 13.0928Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M96.0405 13.0928C97.2475 13.0928 98.2805 13.553 99.1397 14.4633C99.9988 15.3737 100.418 16.4988 100.418 17.8284C100.418 19.1581 99.9886 20.273 99.1397 21.1935C98.7521 21.6315 98.2746 21.9807 97.7398 22.2172C97.2049 22.4538 96.6253 22.5721 96.0405 22.5641C94.7825 22.5641 93.8108 22.1243 93.1357 21.2549V25.8985H90.8242V13.3382H93.1357V14.402C93.8108 13.5224 94.7825 13.0928 96.0405 13.0928ZM93.8312 19.649C94.064 19.8819 94.342 20.0646 94.6481 20.186C94.9542 20.3074 95.2818 20.3648 95.6109 20.3548C95.9414 20.3637 96.2704 20.3059 96.5779 20.1846C96.8855 20.0633 97.1654 19.8812 97.4009 19.649C97.8714 19.1785 98.1066 18.5649 98.1066 17.8284C98.1066 17.092 97.8714 16.4783 97.4009 16.0078C96.9304 15.5373 96.3371 15.3021 95.6109 15.3021C94.8847 15.3021 94.3017 15.5373 93.8312 16.0078C93.3607 16.4783 93.1357 17.092 93.1357 17.8284C93.1357 18.5649 93.3607 19.1826 93.8312 19.649V19.649Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M108.867 13.3377H111.179V22.3078H108.867V21.2441C108.172 22.1135 107.2 22.5533 105.942 22.5533C104.745 22.5533 103.723 22.093 102.863 21.1827C102.004 20.2724 101.585 19.1473 101.585 17.8176C101.585 16.488 102.014 15.3731 102.863 14.4525C103.712 13.532 104.745 13.082 105.942 13.082C107.2 13.082 108.172 13.5218 108.867 14.3912V13.3377ZM104.602 19.6485C104.835 19.8813 105.113 20.064 105.419 20.1854C105.725 20.3068 106.053 20.3642 106.382 20.3542C106.712 20.3632 107.041 20.3053 107.349 20.184C107.656 20.0627 107.936 19.8806 108.172 19.6485C108.642 19.178 108.878 18.5643 108.878 17.8278C108.878 17.0914 108.642 16.4777 108.172 16.0072C107.701 15.5367 107.108 15.3015 106.382 15.3015C105.656 15.3015 105.073 15.5367 104.602 16.0072C104.132 16.4777 103.907 17.0914 103.907 17.8278C103.907 18.5643 104.132 19.178 104.602 19.6485Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M118.276 13.0928C119.258 13.0928 120.066 13.4201 120.71 14.0747C121.355 14.7293 121.672 15.6396 121.672 16.8056V22.3186H119.36V17.092C119.36 16.4988 119.196 16.0385 118.879 15.7214C118.562 15.4043 118.122 15.2509 117.591 15.2509C116.987 15.2509 116.517 15.435 116.159 15.8032C115.801 16.1715 115.617 16.734 115.617 17.4704V22.3186H113.305V13.3526H115.617V14.3549C116.159 13.5019 117.049 13.0928 118.276 13.0928Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M130.438 9.74765H132.75V22.3079H130.438V21.2441C129.753 22.1135 128.792 22.5533 127.534 22.5533C126.327 22.5533 125.294 22.0931 124.434 21.1828C123.575 20.2724 123.156 19.1473 123.156 17.8177C123.156 16.488 123.585 15.3731 124.434 14.4526C125.283 13.5321 126.316 13.082 127.534 13.082C128.792 13.082 129.763 13.5218 130.438 14.3912V9.74765ZM126.173 19.6485C126.644 20.119 127.237 20.3543 127.963 20.3543C128.292 20.3643 128.62 20.3068 128.926 20.1855C129.232 20.0641 129.51 19.8814 129.743 19.6485C130.213 19.178 130.438 18.5643 130.438 17.8279C130.438 17.0915 130.203 16.4778 129.743 16.0073C129.51 15.7745 129.232 15.5917 128.926 15.4703C128.62 15.349 128.292 15.2915 127.963 15.3016C127.633 15.2926 127.304 15.3505 126.996 15.4717C126.689 15.593 126.409 15.7752 126.173 16.0073C125.703 16.4778 125.467 17.0915 125.467 17.8279C125.467 18.5643 125.703 19.1821 126.173 19.6485Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
                <path
                  d="M141.689 13.3377H144V22.3078H141.689V21.2441C140.993 22.1135 140.022 22.5533 138.764 22.5533C137.567 22.5533 136.544 22.093 135.685 21.1827C134.826 20.2724 134.406 19.1473 134.406 17.8176C134.406 16.488 134.836 15.3731 135.685 14.4525C136.534 13.532 137.567 13.082 138.764 13.082C140.022 13.082 140.993 13.5218 141.689 14.3912V13.3377ZM137.424 19.6485C137.656 19.8813 137.934 20.064 138.24 20.1854C138.547 20.3068 138.874 20.3642 139.203 20.3542C139.534 20.3632 139.863 20.3053 140.17 20.184C140.478 20.0627 140.758 19.8806 140.993 19.6485C141.464 19.178 141.699 18.5643 141.699 17.8278C141.699 17.0914 141.464 16.4777 140.993 16.0072C140.523 15.5367 139.93 15.3015 139.203 15.3015C138.477 15.3015 137.894 15.5367 137.424 16.0072C136.953 16.4777 136.728 17.0914 136.728 17.8278C136.728 18.5643 136.953 19.1821 137.424 19.6485Z"
                  fill="var(--text-gray-100)"
                  data-hide={mini}
                  className="transition duration-300"
                />
              </svg>
            </Link>
          </div>
          <div className={cn(styles.workspace_area, 'relative', isLoadingCurrentWorkspace && 'opacity-30')}>
            <div className="rounded-md border-cp-gray-20 border bg-cp-white transition duration-300" data-hide={mini}>
              <div className={styles.workspace_inner}>
                <p className="font-normal cp-text-xs text-cp-gray-40 mb-1">CURRENT WORKSPACE</p>
                {currentWorkspace && workspaces && !!workspaces.length && (
                  <Select
                    components={{ MenuList: SelectMenuButton }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={{
                      name: currentWorkspace.name,
                      value: currentWorkspace.id,
                      image_url: currentWorkspace.image_url,
                    }}
                    onChange={(data) => {
                      updateWorkspace({ id: data?.id })
                      // updateWorkspace({ id: data?.id }).then(() => window.location.reload())
                    }}
                    styles={customStyles}
                    isSearchable={false}
                    formatOptionLabel={formatOptionLabel}
                    options={dropdownData}
                  />
                )}
              </div>
              {currentWorkspace?.is_personal !== 1 && (
                <div className="p-4 border-t border-cp-gray-20">
                  <button
                    className="small text-cp-gray-100 font-medium w-full flex flex-start"
                    onClick={() => setIsOpen(true)}
                  >
                    <i className="mr-2">
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.33341 9.50129V10.8946C6.73003 10.6813 6.08426 10.6159 5.45035 10.7038C4.81644 10.7918 4.21288 11.0306 3.69037 11.4001C3.16786 11.7696 2.74164 12.2592 2.44752 12.8276C2.1534 13.396 1.99996 14.0266 2.00008 14.6666L0.666748 14.666C0.666541 13.8519 0.852696 13.0486 1.21095 12.3176C1.5692 11.5865 2.09004 10.9472 2.73356 10.4486C3.37708 9.95005 4.1262 9.60538 4.92352 9.44106C5.72083 9.27673 6.54519 9.29711 7.33341 9.50063V9.50129ZM6.00008 8.66663C3.79008 8.66663 2.00008 6.87663 2.00008 4.66663C2.00008 2.45663 3.79008 0.666626 6.00008 0.666626C8.21008 0.666626 10.0001 2.45663 10.0001 4.66663C10.0001 6.87663 8.21008 8.66663 6.00008 8.66663ZM6.00008 7.33329C7.47341 7.33329 8.66675 6.13996 8.66675 4.66663C8.66675 3.19329 7.47341 1.99996 6.00008 1.99996C4.52675 1.99996 3.33341 3.19329 3.33341 4.66663C3.33341 6.13996 4.52675 7.33329 6.00008 7.33329ZM10.0001 11.3333V9.33329H11.3334V11.3333H13.3334V12.6666H11.3334V14.6666H10.0001V12.6666H8.00008V11.3333H10.0001Z"
                          fill="var(--text-gray-100)"
                        />
                      </svg>
                    </i>{' '}
                    Invite members
                  </button>
                </div>
              )}
              {isLoadingCurrentWorkspace && <Loading />}
            </div>
          </div>
          <nav className={cn(styles.menu, 'my-4 h-full', opened && 'opacity-30')}>
            <ul className="flex flex-col h-full space-y-1">
              {routes.map((route, index) => {
                const Helper = route?.sidebar?.helper
                const setupActive = setup && !Object.keys(setup).every((key) => setup[key])

                if (route?.sidebar?.slug === 'setup' && !setupActive) {
                  return null
                }

                return (
                  route.sidebar && (
                    <li key={route.path}>
                      <NavLink
                        exact={route.exact}
                        to={`${route.path}`}
                        className={(isActive) =>
                          cn(
                            'flex-shrink-0 flex justify-between items-center ',
                            !(route?.sidebar?.slug === 'setup' && mini) && isActive && styles.active
                          )
                        }
                        activeClassName="text-cp-gray-100"
                        data-testid={`sidebar-navigation-${route?.sidebar?.slug}`}
                      >
                        <div className={cn('rounded-md flex items-center', mini && 'order-2')}>
                          <div
                            className={cn(styles.menu_icon, 'transition duration-300 flex items-center')}
                            data-tip
                            data-for={route?.sidebar?.name}
                            data-padding="2px"
                          >
                            {route.sidebar.icon && route.sidebar.icon}
                          </div>
                          <ReactTooltip
                            id={route?.sidebar?.name}
                            place="right"
                            type="dark"
                            effect="solid"
                            disable={!mini}
                          >
                            <div>{route?.sidebar?.name}</div>
                          </ReactTooltip>
                          <span className="p font-medium transition duration-300">{route.sidebar.name}</span>
                        </div>
                        {Helper && (
                          <div
                            className={cn('flex justify-center items-center h-8', mini && 'order-1')}
                            data-tip
                            data-for={route?.sidebar?.name}
                            data-padding="2px"
                          >
                            <Helper />
                          </div>
                        )}
                      </NavLink>
                    </li>
                  )
                )
              })}
            </ul>
          </nav>
          <div className={cn(styles.workspace, 'flex-shrink-0 flex items-center')}>
            <div className={cn(styles.limits, 'w-full transition duration-300')} data-hide={mini}>
              <ClickOutside onClick={() => setOpen(false)}>
                <div>
                  <div className={cn(styles.limit_box, opened && styles.open_box)}>
                    <div className={styles.arrow_box}>
                      <div className="py-3 px-4 border-b border-cp-gray-20">
                        <span className="p cp-text-xs text-cp-gray-40 uppercase">Usage</span>
                        <p className="p font-medium">{limits?.owner}</p>
                      </div>
                      <ul className="py-3 px-4 space-y-2">
                        {limits?.useges?.map((usage, i) => {
                          return (
                            <li key={usage?.title}>
                              <div className="flex items-center justify-between">
                                <p className="small font-medium">{usage?.title}</p>
                                <span className="flex items-center">
                                  <span className="small font-medium">{usage?.current}</span>
                                  <span className="small font-medium text-cp-gray-40"> / {usage?.limit}</span>
                                </span>
                              </div>
                              <Progress percentage={usage?.percentage ?? 0} color={usage?.color} />
                            </li>
                          )
                        })}
                      </ul>
                      <div className="px-4 mb-3">
                        <p className="cp-text-xs text-cp-gray-40">
                          Your limits reset on {dayjs(limits?.resetDate).format('MMM DD, YYYY')}.
                        </p>
                      </div>
                      <div className="px-4 mb-3 flex items-center justify-center">
                        {/* <Button className="btn btn-success">Upgrade</Button> */}
                        <Link to="/settings/account/billing" className="btn btn-success">
                          Upgrade
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={cn(styles.limits_area, 'cursor-pointer')} onClick={() => setOpen(!opened)}>
                    <p className={cn(styles.limit_text, 'small')}>Usage</p>
                    <div className={styles.status}>
                      <span className={styles.dot} style={{ backgroundColor: limits?.status?.color }} />
                      <p className="small font-medium" style={{ color: limits?.status?.textColor }}>
                        {limits?.status?.text}
                      </p>
                    </div>
                  </div>
                </div>
              </ClickOutside>
            </div>
            <span className={styles.single_dot} style={{ backgroundColor: limits?.status?.color }} />
          </div>
          <div
            id="sidebar-toggle"
            onClick={() => setMini(!mini)}
            className={cn(
              styles.toggle,
              'transition duration-300 flex items-center justify-center absolute rounded-full top-8 z-10 cursor-pointer'
            )}
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.121 9.00005L11.8335 12.7126L10.773 13.7731L6 9.00005L10.773 4.22705L11.8335 5.28755L8.121 9.00005Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        {currentRoute?.sub_sidebar && (
          <section className={styles.app_subsidebar}>
            <div className={cn('relative', isLoadingCurrentWorkspace && 'opacity-30')}>
              {isLoadingCurrentWorkspace && <Loading />}

              <div className="block md:hidden pt-3 px-2">
                {currentRoute?.sub_sidebar?.menu &&
                !!currentRoute?.sub_sidebar?.menu.length &&
                currentRoute?.sub_sidebar?.menu?.length > 1 ? (
                  <Tabs defaultActiveKey={activeCurrentTab?.title}>
                    {currentRoute?.sub_sidebar?.menu?.map((route, index) => {
                      return (
                        <Tab
                          eventKey={route?.title}
                          title={<p className="flex items-center font-medium text-cp-gray-100">{route?.title}</p>}
                        >
                          <div>
                            <ul className={styles.subsidebar_list}>
                              {route?.list?.map((item) => {
                                if (currentWorkspace?.is_personal && ['Members'].includes(item.name)) return null
                                return (
                                  <li key={item.to} className="px-4">
                                    <NavLink
                                      to={`${item.to}`}
                                      className="flex items-center whitespace-nowrap"
                                      activeClassName="text-cp-primary-100"
                                      data-testid={`sidebar-sub-navigation-${currentRoute.slug}-${item.slug}`}
                                    >
                                      {item.icon && <span className="mr-2">{item.icon}</span>}
                                      <span className="cp-text-sm font-medium">{item.name}</span>
                                    </NavLink>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </Tab>
                      )
                    })}
                  </Tabs>
                ) : (
                  <div>
                    <p className="ml-3 h6 mb-2 flex items-center font-semibold text-cp-gray-100">
                      {currentRoute?.sub_sidebar?.icon && (
                        <span className="mr-2">{currentRoute?.sub_sidebar?.icon}</span>
                      )}
                      <span>{currentRoute?.sub_sidebar?.title}</span>
                    </p>
                    {currentRoute?.sub_sidebar?.menu?.map((route, index) => {
                      return (
                        <div key={index}>
                          {route?.title && !route?.onlyMobile && (
                            <div className={styles.subsidebar_second_list}>
                              {route.icon && route.icon}
                              <p className="font-normal cp-text-xs text-cp-gray-40">{route?.title}</p>
                            </div>
                          )}

                          <ul className={styles.subsidebar_list}>
                            {route?.list?.map((item) => {
                              if (currentWorkspace?.is_personal && ['Members'].includes(item.name)) return null

                              return (
                                <li key={item.to} className="px-4">
                                  <NavLink
                                    to={`${item.to}`}
                                    className="flex items-center whitespace-nowrap"
                                    activeClassName="text-cp-primary-100"
                                    data-testid={`sidebar-sub-navigation-${currentRoute.slug}-${item.slug}`}
                                  >
                                    {item.icon && <span className="mr-2">{item.icon}</span>}
                                    <span className="cp-text-sm font-medium">{item.name}</span>
                                  </NavLink>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>

              <div className="hidden md:flex flex-col h-screen">
                {currentRoute.path === '/settings' ? (
                  <div className={cn(styles.workspace_inner, 'mb-4 bg-cp-white border-b border-t border-cp-gray-20')}>
                    <p className="font-normal cp-text-xs text-cp-gray-40 mb-1">WORKSPACE SETTINGS</p>
                    <div className={cn(styles.workspace_settings_image, 'flex items-center')}>
                      <div className="w-8 h-8 overflow-hidden rounded-full relative flex items-center justify-center">
                        <img className="absolute" src={currentWorkspace?.image_url} alt="currentWorkspace" />
                      </div>
                      <p className="flex-1 ml-2 font-medium truncate ">{currentWorkspace?.name}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={cn(
                      styles.workspace_inner_secondary,
                      'px-4 mb-3 flex justify-between flex-wrap items-center'
                    )}
                  >
                    <p className="mb-2 flex items-center font-semibold text-cp-gray-100">
                      {currentRoute?.sub_sidebar?.icon && (
                        <span className="mr-2">{currentRoute?.sub_sidebar?.icon}</span>
                      )}
                      <span>{currentRoute?.sub_sidebar?.title}</span>
                    </p>
                  </div>
                )}
                <div className="flex-1 overflow-y-auto h-full">
                  {currentRoute?.sub_sidebar?.menu &&
                    !!currentRoute?.sub_sidebar?.menu.length &&
                    currentRoute?.sub_sidebar?.menu?.map((route, index) => {
                      return (
                        <div key={index}>
                          {route?.title && !route?.onlyMobile && (
                            <div className={styles.subsidebar_second_list}>
                              {route.icon && route.icon}
                              <p className="font-normal cp-text-xs text-cp-gray-40">{route?.title}</p>
                            </div>
                          )}

                          <ul className={styles.subsidebar_list}>
                            {route?.list?.map((item) => {
                              if (currentWorkspace?.is_personal && ['Members'].includes(item.name)) return null
                              return (
                                <li key={item.to} className="px-4">
                                  <NavLink
                                    to={`${item.to}`}
                                    className="flex items-center whitespace-nowrap"
                                    activeClassName="text-cp-gray-100"
                                    data-testid={`sidebar-sub-navigation-${currentRoute.slug}-${item.slug}`}
                                  >
                                    {item.icon && <span className="mr-2">{item.icon}</span>}
                                    <span className="cp-text-sm font-medium">{item.name}</span>
                                  </NavLink>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </section>
        )}
      </aside>

      <Modal
        overlayClassName="medium-modal-overlay"
        className="medium-modal-center"
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <div className="medium-modal-content">
          <span className="medium-modal-close" onClick={() => setIsOpen(false)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99999 7.05734L11.3 3.75734L12.2427 4.70001L8.94266 8.00001L12.2427 11.3L11.3 12.2427L7.99999 8.94267L4.69999 12.2427L3.75732 11.3L7.05732 8.00001L3.75732 4.70001L4.69999 3.75734L7.99999 7.05734Z"
                fill="var(--text-gray-100)"
              />
            </svg>
          </span>
          <div className="medium-modal-title">
            <h5>Invite members to your workspace</h5>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                email: '',
                role: 'member',
              }}
              validationSchema={Schema}
              onSubmit={(values, { resetForm }) => {
                workspaceInvite({
                  body: values,
                }).then((response) => {
                  if (response?.error) {
                    setHasError(true)
                    return
                  }
                  if (response?.data) {
                    alert('You have successfully update the workspace', {
                      type: 'success',
                    })
                    setIsOpen(false)
                    push('/settings/members')
                  }
                })
              }}
            >
              {({ errors, touched, dirty }) => (
                <Form className="w-full">
                  <div className="medium-modal-body">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 md:col-span-6">
                        <Field name="email" label="Email address" className="input-default w-full" component={Input} />
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Field name="role" label="Role" component={CustomSelect} options={options} />
                      </div>
                    </div>
                  </div>
                  <div className="medium-modal-footer">
                    <div className="space-y-2 md:space-y-0 items-start w-full flex flex-col md:flex-row md:items-center md:justify-end">
                      <p className="small flex-1 text-cp-gray-60">Invite members to access your workspace easily.</p>
                      <Button
                        disabled={
                          !!Object.keys(errors || {}).length ||
                          (!Object.keys(errors || {}).length && !dirty) ||
                          isLoadingInvite
                        }
                        loading={isLoadingInvite && !hasError}
                        type="submit"
                        className="btn-primary btn-small"
                      >
                        Send invitation
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Sidebar
