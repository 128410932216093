import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './AppRender.module.scss'
import ClickOutside from '../../../lib/click-outside'

const list = {
  start: [
    {
      title: 'Quick start',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: true,
    },
    {
      title: 'Tour of CabinPanda',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Data editor',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Basics',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Adding & Editing',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Security',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
  ],
  data: [
    {
      title: 'Quick Data',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: true,
    },
    {
      title: 'Tour of CabinPanda',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Data editor',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Basics',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Adding & Editing',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Security',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
  ],
  tutorials: [
    {
      title: 'Quick Tutorials',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: true,
    },
    {
      title: 'Tour of CabinPanda',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Data editor',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Basics',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Adding & Editing',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
    {
      title: 'Security',
      description: null,
      children: (
        <p>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim metus, rhoncus ut rutrum non, convallis
          ut nisl. Aenean vel ligula libero. Aenean sem purus, convallis posuere velit vel, laoreet tristique leo.
          Integer sodales semper ornare. Vestibulum ac mollis elit. Vivamus volutpat nunc ut sem ornare, nec consequat
          turpis pretium. Aliquam bibendum feugiat lectus in euismod. Nunc ac suscipit erat. Phasellus velit mauris,
          semper et sem ut, tempor cursus odio. Donec vel euismod quam. Proin at nulla ut eros mollis tristique
          malesuada at mauris. Donec lobortis venenatis massa, eget bibendum purus efficitur finibus. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. */}
        </p>
      ),
      isOpen: false,
    },
  ],
}

const Learn = (props) => {
  const { isOpenLearn, setLearnOpen } = props
  const [tab, setTab] = useState('start')
  const [items, setItems] = useState(list)

  const handleCollapse = (currentTab, index) => {
    const parseData = Object.keys(items).reduce((acc, key) => {
      acc[key] = items[key].map((item, idx) => {
        if (key === currentTab && idx === index) {
          item.isOpen = !item.isOpen
        } else {
          item.isOpen = false
        }
        return item
      })
      return acc
    }, {})
    setItems(parseData)
  }

  useEffect(() => {
    if (tab) {
      handleCollapse(tab, 0)
    }
  }, [tab])

  return (
    <div className={cn(styles.learn, isOpenLearn ? 'absolute' : 'hidden', isOpenLearn && styles.active)}>
      <ClickOutside onClick={() => setLearnOpen(false)}>
        <div className={cn(styles.learn_inner, isOpenLearn && styles.active)}>
          <div className="flex flex-col px-6 w-full">
            <div className="flex justify-between">
              <h5 className="font-semibold mb-6">Learn to CabinPanda</h5>
              <span className={styles.close} onClick={() => setLearnOpen(false)}>
                <i className="icon-close"></i>
              </span>
            </div>
            <ul className="space-x-4 flex items-center">
              <li
                className={cn(
                  'border-b-2 border-transparent cursor-pointer pb-2',
                  tab === 'start' ? 'border-cp-primary-100 text-cp-gray-100' : 'text-cp-gray-40'
                )}
                onClick={() => setTab('start')}
              >
                <p className="small font-medium">Start here</p>
              </li>
              <li
                className={cn(
                  'border-b-2 border-transparent cursor-pointer pb-2',
                  tab === 'data' ? 'border-cp-primary-100 text-cp-gray-100' : 'text-cp-gray-40'
                )}
                onClick={() => setTab('data')}
              >
                <p className="small font-medium">Data</p>
              </li>
              <li
                className={cn(
                  'border-b-2 border-transparent cursor-pointer pb-2',
                  tab === 'tutorials' ? 'border-cp-primary-100 text-cp-gray-100' : 'text-cp-gray-40'
                )}
                onClick={() => setTab('tutorials')}
              >
                <p className="small font-medium">Tutorials</p>
              </li>
            </ul>
          </div>
          <ul className="flex flex-col divide-y divide-cp-gray-10 mx-6 overflow-y-auto">
            {items[tab] &&
              !!items[tab].length &&
              items[tab].map((item, index) => {
                return (
                  <li key={item?.title} className="py-4" onClick={() => handleCollapse(tab, index)}>
                    <div className="flex items-center justify-between cursor-pointer">
                      <p className="flex-1">{item?.title}</p>
                      <span>
                        <i className={cn('icon-arrow block transform', item.isOpen ? 'rotate-0' : '-rotate-90')}></i>
                      </span>
                    </div>
                    <div className={cn(styles.collapse_children, item.isOpen && styles.active)}>
                      {item.children && <p className="small flex-1">{item.children}</p>}
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </ClickOutside>
    </div>
  )
}

export default Learn
