import React, { useState, useEffect, memo } from 'react'
import styles from './Tabs.module.scss'
import cn from 'classnames'

const MemoizedMovie = (props) => {
  const { children } = props
  return <div>{children}</div>
}

export const Tab = memo(MemoizedMovie)

const Tabs = (props) => {
  const { children, defaultActiveKey, className } = props

  const [tab, setTab] = useState(null)

  useEffect(() => {
    if (children && !!children.length) {
      const currentIndex = children.findIndex((child) => child?.props?.eventKey === defaultActiveKey)
      setTab(currentIndex !== -1 ? currentIndex : 0)
    }
  }, [children, defaultActiveKey])

  return (
    <div>
      <ul className={cn('space-x-4 flex items-center mb-4', styles.tabs, className)}>
        {children.map((elem, index) => {
          return (
            <li
              className={cn(
                'border-b-2 border-transparent cursor-pointer pb-2',
                tab === index ? 'border-cp-primary-100 text-cp-gray-100' : 'text-cp-gray-40'
              )}
              key={`${elem?.props?.eventKey}-${index}`}
              onClick={() => {
                setTab(index)
                // onChange(children[index]?.props?.eventKey)
              }}
            >
              <p className="small uppercase">{elem?.props?.title}</p>
            </li>
          )
        })}
      </ul>
      <div>{children[tab]}</div>
    </div>
  )
}

export default Tabs
