import cn from 'classnames'
import React from 'react'

const Button = (props, buttonRef) => {
  const {
    type,
    className,
    children,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    ...rest
  } = props

  const rootClassName = cn('btn', loading && 'opacity-0', className)

  return (
    <Component
      className={rootClassName}
      disabled={disabled}
      type={type}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {children}
      {loading && (
        <div className="loading-dots">
          <span />
          <span />
          <span />
        </div>
      )}
    </Component>
  )
}

export default Button
