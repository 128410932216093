import React, { lazy } from 'react'

const routes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('./pages/Home')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/forms',
    slug: 'forms',
    component: lazy(() => import('./pages/Forms/index.js')),
    authRequired: true,
    type: 'dashboard-layout',
    sidebar: {
      name: 'Forms',
      slug: 'forms',
      icon: <i className="icon-form"></i>,
    },
    sub_sidebar: {
      title: 'Forms',
      slug: 'forms',
      icon: <i className="icon-form"></i>,
      menu: [
        {
          title: null,
          list: [
            {
              to: '/forms/regular-form',
              slug: 'regular-form',
              name: 'Regular form',
            },
            {
              to: '/forms/backendless-form',
              slug: 'backendless-form',
              name: 'Backendless form',
            },
            {
              to: '/forms/popup-form',
              slug: 'popup-form',
              name: 'Popup form',
            },
            {
              to: '/forms/conversational-form',
              slug: 'conversational-form',
              name: 'Conversational form',
            },
          ],
        },
      ],
    },
  },
  {
    path: '/forms/regular-form',
    component: lazy(() => import('./pages/Forms')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/forms/backendless-form',
    component: lazy(() => import('./pages/Forms')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/forms/popup-form',
    component: lazy(() => import('./pages/Forms')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/forms/conversational-form',
    component: lazy(() => import('./pages/Forms')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/scheduling/:id/options',
    component: lazy(() => import('./pages/SchedulingEditor')),
    authRequired: true,
    type: 'scheduling-editor',
  },
  {
    path: '/scheduling/:id/form-editor',
    component: lazy(() => import('./pages/SchedulingEditor')),
    authRequired: true,
    type: 'scheduling-editor',
  },
  {
    path: '/scheduling/:id/design',
    component: lazy(() => import('./pages/SchedulingEditor')),
    authRequired: true,
    type: 'scheduling-editor',
  },
  {
    path: '/scheduling/:id/settings',
    component: lazy(() => import('./pages/SchedulingEditor')),
    authRequired: true,
    type: 'scheduling-editor',
  },
  {
    path: '/scheduling/:id/bookings',
    component: lazy(() => import('./pages/SchedulingEditor')),
    authRequired: true,
    type: 'scheduling-editor',
  },

  {
    path: '/scheduling',
    slug: 'scheduling',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
    sidebar: {
      name: 'Scheduling',
      slug: 'scheduling',
      icon: <i className="icon-calendar"></i>,
    },
    sub_sidebar: {
      title: 'Scheduling',
      slug: 'scheduling',
      icon: <i className="icon-calendar"></i>,
      menu: [
        {
          title: null,
          list: [
            {
              to: '/scheduling/links',
              slug: 'links',
              name: 'Links',
              exact: true,
            },
            {
              to: '/scheduling/availability',
              slug: 'availability',
              name: 'Availability',
            },
            {
              to: '/scheduling/bookings',
              slug: 'bookings',
              name: 'Bookings',
              exact: true,
            },
            {
              to: '/scheduling/workflows',
              slug: 'workflows',
              name: 'Workflows',
            },
            {
              to: '/scheduling/integrations',
              slug: 'integrations',
              name: 'Integrations',
            },
            {
              to: '/scheduling/intro',
              slug: 'intro',
              name: 'Intro',
              exact: true,
            },
            {
              to: '/scheduling/settings',
              slug: 'settings',
              name: 'Settings',
            },
          ],
        },
      ],
    },
  },

  {
    path: '/kiosk',
    component: lazy(() => import('./pages/Kiosk')),
    authRequired: true,
    type: 'scheduling-editor',
  },
  {
    path: '/scheduling/links/all',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/scheduling/links/multi-use',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/scheduling/links/single-use',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/scheduling/workflows/create',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/scheduling/bookings/upcoming',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/scheduling/bookings/past',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  // {
  //   path: '/scheduling/bookings/pending',
  //   component: lazy(() => import('./pages/Scheduling')),
  //   authRequired: true,
  //   type: 'dashboard-layout',
  // },
  {
    path: '/scheduling/bookings/cancelled',
    component: lazy(() => import('./pages/Scheduling')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  {
    path: '/editor/:id',
    component: lazy(() => import('./pages/Editor')),
    authRequired: true,
    type: 'form-layout',
  },
  {
    path: '/settings',
    slug: 'settings',
    component: lazy(() => import('./pages/Settings/index.js')),
    authRequired: true,
    type: 'dashboard-layout',
    sidebar: {
      name: 'Settings',
      slug: 'settings',
      icon: <i className="icon-settings"></i>,
    },
    sub_sidebar: {
      title: 'Settings',
      slug: 'settings',
      icon: <i className="icon-settings"></i>,
      menu: [
        {
          onlyMobile: true,
          title: 'WORKSPACE SETTINGS',
          icon: null,
          list: [
            {
              to: '/settings/workspace-profile',
              slug: 'workspace-profile',
              name: 'Overview',
              // icon: (
              //   <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M6.66667 6.49984C7.58333 6.49984 8.33333 7.24984 8.33333 8.1665C8.33333 9.08317 7.58333 9.83317 6.66667 9.83317C5.75 9.83317 5 9.08317 5 8.1665C5 7.24984 5.75 6.49984 6.66667 6.49984ZM10 13.1665H3.33333V12.3332C3.33333 11.2248 5.55833 10.6665 6.66667 10.6665C7.775 10.6665 10 11.2248 10 12.3332V13.1665ZM16.6667 5.6665H11.6667V7.33317H16.6667V5.6665ZM16.6667 8.99984H11.6667V10.6665H16.6667V8.99984ZM16.6667 12.3332H11.6667V13.9998H16.6667V12.3332ZM18.3333 2.33317H11.6667V3.99984H18.3333V15.6665H1.66667V3.99984H8.33333V2.33317H1.66667C0.75 2.33317 0 3.08317 0 3.99984V15.6665C0 16.5832 0.75 17.3332 1.66667 17.3332H18.3333C19.25 17.3332 20 16.5832 20 15.6665V3.99984C20 3.08317 19.25 2.33317 18.3333 2.33317ZM10.8333 3.99984H9.16667V0.666504H10.8333V3.99984Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/members',
              slug: 'members',
              name: 'Members',
              // icon: (
              //   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C10.76 2 11.5 2.11 12.2 2.31L13.77 0.74C12.61 0.26 11.34 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10H18ZM5.91 8.08L4.5 9.5L9 14L19 4L17.59 2.58L9 11.17L5.91 8.08Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/integrations',
              slug: 'integrations',
              name: 'Integrations',
              // icon: (
              //   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M16 9H12.82C12.4 7.84 11.3 7 10 7C8.7 7 7.6 7.84 7.18 9H4C3.67 9 2 8.9 2 7V6C2 4.17 3.54 4 4 4H14.18C14.6 5.16 15.7 6 17 6C18.66 6 20 4.66 20 3C20 1.34 18.66 0 17 0C15.7 0 14.6 0.84 14.18 2H4C2.39 2 0 3.06 0 6V7C0 9.94 2.39 11 4 11H7.18C7.6 12.16 8.7 13 10 13C11.3 13 12.4 12.16 12.82 11H16C16.33 11 18 11.1 18 13V14C18 15.83 16.46 16 16 16H5.82C5.4 14.84 4.3 14 3 14C1.34 14 0 15.34 0 17C0 18.66 1.34 20 3 20C4.3 20 5.4 19.16 5.82 18H16C17.61 18 20 16.93 20 14V13C20 10.07 17.61 9 16 9ZM17 2C17.55 2 18 2.45 18 3C18 3.55 17.55 4 17 4C16.45 4 16 3.55 16 3C16 2.45 16.45 2 17 2ZM3 18C2.45 18 2 17.55 2 17C2 16.45 2.45 16 3 16C3.55 16 4 16.45 4 17C4 17.55 3.55 18 3 18Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/fonts',
              slug: 'fonts',
              name: 'Fonts',
              // icon: (
              //   <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M4.12 9L6.5 2.67L8.87 9H4.12ZM5.5 0L0 14H2.25L3.37 11H9.62L10.75 14H13L7.5 0H5.5ZM17 2L12 7.07L13.41 8.5L16 5.9V12H18V5.9L20.59 8.5L22 7.07L17 2Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            // {
            //   to: '/settings/calendar-integration',
            //   slug: 'calendar-integration',
            //   name: 'Calendar Integration',
            //   // icon: (
            //   //   <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            //   //     <path
            //   //       d="M12.75 2.75H15.75C15.9489 2.75 16.1397 2.82902 16.2803 2.96967C16.421 3.11032 16.5 3.30109 16.5 3.5V15.5C16.5 15.6989 16.421 15.8897 16.2803 16.0303C16.1397 16.171 15.9489 16.25 15.75 16.25H2.25C2.05109 16.25 1.86032 16.171 1.71967 16.0303C1.57902 15.8897 1.5 15.6989 1.5 15.5V3.5C1.5 3.30109 1.57902 3.11032 1.71967 2.96967C1.86032 2.82902 2.05109 2.75 2.25 2.75H5.25V1.25H6.75V2.75H11.25V1.25H12.75V2.75ZM11.25 4.25H6.75V5.75H5.25V4.25H3V7.25H15V4.25H12.75V5.75H11.25V4.25ZM15 8.75H3V14.75H15V8.75Z"
            //   //       fill="black"
            //   //     ></path>
            //   //   </svg>
            //   // ),
            // },
            {
              to: '/settings/customize',
              slug: 'customize',
              name: 'Customize',
              // icon: (
              //   <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
              //     <path
              //       d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/privacy',
              slug: 'privacy',
              name: 'Privacy',
              // icon: (
              //   <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M8 16C6.89 16 6 15.1 6 14C6 12.89 6.89 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM14 19V9H2V19H14ZM14 7C15.1 7 16 7.9 16 9V19C16 20.1 15.1 21 14 21H2C0.89 21 0 20.1 0 19V9C0 7.89 0.89 7 2 7H3V5C3 2.24 5.24 0 8 0C10.76 0 13 2.24 13 5V7H14ZM8 2C6.34 2 5 3.34 5 5V7H11V5C11 3.34 9.66 2 8 2Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
          ],
        },
        {
          title: 'ACCOUNT SETTINGS',
          icon: <i className="icon-settings mr-2"></i>,
          list: [
            {
              to: '/settings/account/overview',
              slug: 'overview',
              name: 'Overview',
              // icon: (
              //   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.07 16.28C5.5 15.38 8.12 14.5 10 14.5C11.88 14.5 14.5 15.38 14.93 16.28C13.57 17.36 11.86 18 10 18C8.14 18 6.43 17.36 5.07 16.28ZM16.36 14.83C14.93 13.09 11.46 12.5 10 12.5C8.54 12.5 5.07 13.09 3.64 14.83C2.62 13.5 2 11.82 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.82 17.38 13.5 16.36 14.83ZM10 4C8.06 4 6.5 5.56 6.5 7.5C6.5 9.44 8.06 11 10 11C11.94 11 13.5 9.44 13.5 7.5C13.5 5.56 11.94 4 10 4ZM10 9C9.17 9 8.5 8.33 8.5 7.5C8.5 6.67 9.17 6 10 6C10.83 6 11.5 6.67 11.5 7.5C11.5 8.33 10.83 9 10 9Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/account/workspaces',
              slug: 'workspaces',
              name: 'Workspaces',
              // icon: (
              //   <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M12 0C10.07 0 8.5 1.57 8.5 3.5C8.5 5.43 10.07 7 12 7C13.93 7 15.5 5.43 15.5 3.5C15.5 1.57 13.93 0 12 0ZM12 2C12.83 2 13.5 2.67 13.5 3.5C13.5 4.33 12.83 5 12 5C11.17 5 10.5 4.33 10.5 3.5C10.5 2.67 11.17 2 12 2ZM5.5 3C4.12 3 3 4.12 3 5.5C3 6.44 3.53 7.25 4.29 7.68C4.65 7.88 5.06 8 5.5 8C5.94 8 6.35 7.88 6.71 7.68C7.08 7.47 7.39 7.17 7.62 6.81C6.89 5.86 6.5 4.7 6.5 3.5C6.5 3.41 6.5 3.31 6.5 3.22C6.2 3.08 5.86 3 5.5 3ZM18.5 3C18.14 3 17.8 3.08 17.5 3.22C17.5 3.31 17.5 3.41 17.5 3.5C17.5 4.7 17.11 5.86 16.38 6.81C16.5 7 16.63 7.15 16.78 7.3C16.94 7.45 17.1 7.58 17.29 7.68C17.65 7.88 18.06 8 18.5 8C18.94 8 19.35 7.88 19.71 7.68C20.47 7.25 21 6.44 21 5.5C21 4.12 19.88 3 18.5 3ZM12 9C9.66 9 5 10.17 5 12.5V14H19V12.5C19 10.17 14.34 9 12 9ZM4.71 9.55C2.78 9.78 0 10.76 0 12.5V14H3V12.07C3 11.06 3.69 10.22 4.71 9.55ZM19.29 9.55C20.31 10.22 21 11.06 21 12.07V14H24V12.5C24 10.76 21.22 9.78 19.29 9.55ZM12 11C13.53 11 15.24 11.5 16.23 12H7.77C8.76 11.5 10.47 11 12 11Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/account/billing',
              slug: 'billing',
              name: 'Billing',
            },
            {
              to: '/settings/account/usage',
              slug: 'usage',
              name: 'Usage',
            },
            {
              to: '/settings/account/security',
              slug: 'security',
              name: 'Security',
              // icon: (
              //   <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 2.18L16 5.3V10.22C16 11.92 15.5 13.65 14.65 15.17C13 13.94 10.26 13.5 9 13.5C7.74 13.5 5 13.94 3.35 15.17C2.5 13.65 2 11.92 2 10.22V5.3L9 2.18ZM9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM9 15.5C10.57 15.5 12.64 16.11 13.53 16.84C12.29 18.38 10.7 19.55 9 20C7.3 19.55 5.71 18.38 4.47 16.84C5.37 16.11 7.43 15.5 9 15.5Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
            {
              to: '/settings/account/api',
              slug: 'api',
              name: 'API',
              // icon: (
              //   <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              //     <path
              //       d="M12.6 10.6L17.2 6L12.6 1.4L14 0L20 6L14 12L12.6 10.6ZM7.4 10.6L2.8 6L7.4 1.4L6 0L0 6L6 12L7.4 10.6Z"
              //       fill="#3C3C3C"
              //     ></path>
              //   </svg>
              // ),
            },
          ],
        },
      ],
    },
  },
  {
    path: '/setup',
    component: lazy(() => import('./pages/Setup')),
    authRequired: true,
    type: 'dashboard-layout',
    sidebar: {
      name: 'Setup',
      slug: 'setup',
      icon: (
        <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.4987 15.1667C4.8167 15.1667 1.83203 12.182 1.83203 8.50004C1.83203 5.51471 3.79403 2.98804 6.4987 2.13871V3.55471C5.35265 4.02007 4.4039 4.86918 3.81474 5.95678C3.22558 7.04439 3.03262 8.30291 3.26887 9.51707C3.50512 10.7312 4.15589 11.8256 5.10987 12.6129C6.06386 13.4003 7.26177 13.8317 8.4987 13.8334C9.56122 13.8333 10.5995 13.5161 11.4806 12.9222C12.3617 12.3283 13.0453 11.4849 13.444 10.5H14.86C14.0107 13.2047 11.484 15.1667 8.4987 15.1667ZM15.132 9.16671H7.83203V1.86671C8.05136 1.84471 8.27403 1.83337 8.4987 1.83337C12.1807 1.83337 15.1654 4.81804 15.1654 8.50004C15.1654 8.72471 15.154 8.94737 15.132 9.16671ZM9.16536 3.20804V7.83337H13.7907C13.6423 6.65844 13.1073 5.56626 12.2699 4.72886C11.4325 3.89146 10.3403 3.35643 9.16536 3.20804Z"
            fill="currentColor"
          />
        </svg>
      ),

      helper: lazy(() => import('./pages/Setup/Progress')),
    },
  },
  {
    path: '/billing',
    component: lazy(() => import('./pages/Billing/index.js')),
    authRequired: true,
    type: 'dashboard-layout',
  },
  { path: '/email/verify/:userId/:token', component: lazy(() => import('./pages/Auth/verify')) },
  { path: '/onboarding', component: lazy(() => import('./pages/Onboarding')), type: 'create-form' },
  { path: '/typography', component: lazy(() => import('./pages/Typography')) },

  {
    path: '/create-form/regular-form',
    component: lazy(() => import('./pages/CreateForm/subpages/regular-form')),
    type: 'create-form',
  },
  {
    path: '/create-form/scheduling-form',
    component: lazy(() => import('./pages/CreateForm/subpages/scheduling-form')),
    type: 'create-form',
  },
  { path: '/create-form', component: lazy(() => import('./pages/CreateForm')), type: 'create-form' },
  // { path: '/event/cancel/:token', component: lazy(() => import('./pages/Auth/cancelEvent')) },
  {
    path: '*',
    component: lazy(() => import('./components/NotFound')),
  },
]

const authRoutes = [
  { path: '/login', component: lazy(() => import('./pages/Auth/login')) },
  { path: '/register', component: lazy(() => import('./pages/Auth/register')) },
  { path: '/password/email', component: lazy(() => import('./pages/Auth/forgot')) },
  { path: '/password/reset/:token', component: lazy(() => import('./pages/Auth/reset')) },
  { path: '/auth/:provider/callback', component: lazy(() => import('./pages/Auth/provider')) },
  // { path: '/event/cancel/:token', component: lazy(() => import('./pages/Auth/cancelEvent')) },
]

// /password/reset/8e270eafbe67b94da1fa9baedfa34637f37996db162426fdf77272717d51f854?email=serdar.basol%40saypr.com

export { routes, authRoutes }
