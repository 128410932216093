import React from 'react'
import cn from 'classnames'
import { Link, useHistory, matchPath } from 'react-router-dom'
import FormHeader from './FormHeader'
import DashboardHeader from './DashboardHeader'
import { authRoutes } from '../../../route'

import styles from './styles.module.scss'

const _renderHeader = (pathname) => {
  switch (pathname) {
    case 'dashboard':
      return <DashboardHeader />
    case 'form':
      return <FormHeader />
    default:
      return null
  }
}

const handlePathRule = (pathname) => {
  if (authRoutes.some((route) => matchPath(pathname, { path: route.path }))) {
    return null
  }
  if (pathname.includes('/editor/')) {
    return 'form'
  }
  return 'dashboard'
}

const Header = ({ backLink = '/', children }) => {
  const history = useHistory()

  if (!children && history.location.pathname.includes('/create-form')) {
    return null
  }

  const type = handlePathRule(history.location.pathname)
  return (
    <header className="bg-cp-white step-header fixed w-full top-0 left-0 z-10">
      <div
        className={cn(
          type !== 'form' && 'container-fluid',
          'px-4 h-14 mx-auto space-y-4 space-y-0 flex items-center justify-between space-x-10'
        )}
      >
        <div className={cn(styles.logo, 'w-full flex justify-between items-center')}>
          <Link to={backLink} className="flex items-center">
            <div className="relative group">
              <div className="h-full absolute flex items-center cursor-pointer opacity-0 group-hover:opacity-100 z-0">
                <i className="icon-arrow-left mr-1" />
                <span className="text-xs">Back</span>
              </div>
              <div className="flex items-center mr-4 ml-1 w-10 group-hover:opacity-0 z-10">
                <svg width="48" height="34" viewBox="0 0 48 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.8087 4.67338C14.0939 4.67338 15.1359 3.6272 15.1359 2.33669C15.1359 1.04617 14.0939 0 12.8087 0C11.5234 0 10.4814 1.04617 10.4814 2.33669C10.4814 3.6272 11.5234 4.67338 12.8087 4.67338Z"
                    fill="var(--text-gray-100)"
                  />
                  <path
                    d="M35.1924 4.67338C36.4777 4.67338 37.5196 3.6272 37.5196 2.33669C37.5196 1.04617 36.4777 0 35.1924 0C33.9072 0 32.8652 1.04617 32.8652 2.33669C32.8652 3.6272 33.9072 4.67338 35.1924 4.67338Z"
                    fill="var(--text-gray-100)"
                  />
                  <path
                    d="M2 16.3958C2 24.3563 8.4294 32.0001 16.338 32.0001C19.1582 32.0001 21.7813 31.3268 23.9901 30.099C27.9937 27.8812 30.6759 23.8613 30.6759 18.7324C30.6759 14.5739 27.6979 11.2075 24.0296 11.2075C20.3613 11.2075 17.3241 14.5739 17.3241 18.7324C17.3241 23.8613 19.9866 27.8812 24.0099 30.099C26.2187 31.3268 28.8615 32.0001 31.662 32.0001C39.5903 32.0001 46 24.3365 46 16.376"
                    stroke="var(--text-gray-100)"
                    strokeWidth="3.22535"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
            </div>
          </Link>

          <div className="flex-1">{children ?? _renderHeader(type)}</div>
        </div>
      </div>
    </header>
  )
}

export default Header
