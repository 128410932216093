import React, { useEffect, useState } from 'react'
import styles from './CodeMirrorView.module.scss'
import Button from '../Button'
import cn from 'classnames'

import { selectFormId, selectFormData, selectIsDirty } from '../../../modules/reducers/app'
import { useSelector } from 'react-redux'

import { Controlled as CodeMirror } from 'react-codemirror2'
// import 'codemirror/mode/css/css.js'
// import 'codemirror/mode/htmlmixed/htmlmixed'
// import 'codemirror/mode/javascript/javascript.js'
// import 'codemirror/mode/xml/xml.js'
var beautify_js = require('js-beautify').js_beautify
var beautify_html = require('js-beautify').html

export const TYPES = {
  RICH_TEXT: 'rich_text',
  SINGLE_LINE_TEXT: 'single_line_text',
  SHORT_ANSWER: 'short-answer',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  NUMBER: 'number',
  DATEPICKER: 'date_picker',
  FILE: 'file',
  MULTI_LINE_TEXT: 'multi_line_text',
  ADDRESS: 'address',
  DROPDOWN: 'dropdown_select',
  RADIO: 'radio_select',
  MULTIPLE_CHECKBOX: 'multiple_checkboxes',
  SINGLE_CHECKBOX: 'single_checkbox',
  HIDDEN: 'hidden',
}

// prettier-ignore
const generateInput = (val) => {
  const attirbutes = `${Object.entries(val.attributes)
    .map(([key, value]) => {
      if (key === 'hide_label' || 
          key === 'unique_field' || 
          key === 'multiple_selection' ||
          key === 'rows' ||
          key === 'cols'
        ) return null
      if (key !== 'required') {
        return `${key}="${value}"  `
      }
      if (value !== '0') return `${key}  `
      return ''
    })
    .join('')}`
  switch (val.field_type) {
    case TYPES.SINGLE_LINE_TEXT:
    case TYPES.SHORT_ANSWER:
    case TYPES.NAME:
    case TYPES.EMAIL:
    case TYPES.PHONE:
    case TYPES.NUMBER:
    case TYPES.DATEPICKER:
    case TYPES.FILE:
      return `
        <div>
          <label for="${val.key}"> ${val.label} </label>
          <input id="${val.key}" name="${val.key}"  type="${
            val.field_type === TYPES.NUMBER
              ? 'number'
              : val.field_type === TYPES.FILE
              ? 'file'
              : val.field_type === TYPES.DATEPICKER
              ? 'date'
              : 'text'
          }"  ${attirbutes}/>
        </div>
      `
    case TYPES.MULTI_LINE_TEXT:
    case TYPES.ADDRESS:
      return `
        <div>
          <label for="${val.key}"> ${val.label} </label>
          <textarea id="${val.key}" name="${val.key}" ${attirbutes}></textarea>
        </div>
      `

    case TYPES.HIDDEN:
      return `
        <input value="${val.default_value}" type="hidden" />
      `

    case TYPES.DROPDOWN:
      return `
        <div>
          <label for="${val.key}"> ${val.label} </label>
          <select id="${val.key}" name="${val.key}">
            ${val.options?.map((option) => `<option value="${option.id}">${option.name}</option>`).join('')}
          </select>
        </div>
      `
    case TYPES.RADIO:
    case TYPES.MULTIPLE_CHECKBOX:
    case TYPES.SINGLE_CHECKBOX:
      return val.options
        ?.map(
          (option) => `
          <div>
            <label for="${val.key}-${option.id}">${option.name}</label>
            <input id="${val.key}-${option.id}" type="${val.field_type === 'radio_select' ? 'radio' : 'checkbox'}" name="${val.key}[]" value="${option.id}" />
          </div>
        `).join('')
    default:
      return ''
  }
}

const CodeMirrorView = (props) => {
  const { data, className, inBuilder, onlyRender, type = 'html' } = props
  const [editor, setEditor] = useState()
  const id = useSelector(selectFormId)
  const form = useSelector(selectFormData)
  const isDirty = useSelector(selectIsDirty)

  useEffect(() => {
    if (!onlyRender) {
      let ed = ''
      if (data?.fields?.length) {
        data?.fields?.forEach((item) => {
          const a = generateInput(item)
          ed = ed + a
        })
      }

      setEditor(`
        <form method="POST" action="${process.env.REACT_APP_BASE_URL}/form/submit/backendless/${id}" enctype="multipart/form-data">
          <input name="key" value="${id}" type="hidden" />
            ${ed}
          <input value="Submit" type="submit" />
        </form>
      `)
    } else {
      setEditor(data)
    }
  }, [data, onlyRender])

  const [copyText, setCopyText] = useState('Copy')

  function copy() {
    setCopyText('Copied!')
    navigator.clipboard.writeText(editor)
  }

  return (
    <div className={cn(className, styles.codeMirror, inBuilder && styles.inBuilder)}>
      <CodeMirror
        value={
          type === 'html'
            ? beautify_html(editor, { indent_size: 2 })
            : type === 'js'
            ? beautify_js(editor, { indent_size: 2 })
            : editor
        }
        options={{
          lineWrapping: true,
          tabSize: 2,
          readOnly: true,
          htmlMode: true,
          theme: 'nord',
          mode: 'htmlmixed',
          // styleActiveLine: true,
          gutters: ['CodeMirror-lint-markers'],
          lineNumbers: true,
          line: true,
        }}
        onChange={(editor, data, value) => {}}
      />

      <div className={styles.copyButton}>
        {(form && form?.revision_id !== form?.published_revision_id) || isDirty ? (
          <p className="my-5 px-5 py-5 rounded-md border border-cp-gray-10 dark:border-cp-gray-0 cp-text-tiny badged-warning text-black">
            ⚠️ You have <b>unpublished</b> changes.
          </p>
        ) : (
          <Button onClick={copy} className="btn-primary">
            {copyText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CodeMirrorView
