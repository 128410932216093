import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAuth } from '../modules/reducers/app'
import { useGetUserQuery, useUpdateUserMutation } from '../modules/services/workspaceApi'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import { setClearAll } from '../modules/reducers/app'
import { useLazyLogoutQuery, useResendVerificationMutation } from '../modules/services/authApi'
import { useGetWorkspaceQuery } from '../modules/services/workspaceApi'
import { useDispatch } from 'react-redux'
import alert from 'react-hot-toast'

Modal.setAppElement('#root')

const Authmiddleware = (props) => {
  const { component, path } = props
  const { token } = useSelector(selectAuth)
  const { data: user } = useGetUserQuery(null, { refetchOnMountOrArgChange: true })
  const [updateUser] = useUpdateUserMutation()
  const { push, location } = useHistory()
  const [trigger, result] = useLazyLogoutQuery()
  const [resend, { isLoading }] = useResendVerificationMutation()

  const { data: currentWorkspace } = useGetWorkspaceQuery({
    skip: token,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (location?.pathname && location?.pathname !== '/forms/regular-form' && location?.pathname !== '/onboarding') {
      if (user && !user?.email_verified_at) {
        push('/forms/regular-form')
      }
    }
  }, [location, user])

  useEffect(() => {
    if (user && !user?.timezone) {
      updateUser({
        body: {
          timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        },
      })
    }
  }, [user])

  useEffect(() => {
    if (result?.isSuccess && result?.data && result?.data?.message) {
      dispatch(setClearAll())

      alert(result?.data?.message ?? 'Logout succesfully', {
        type: 'success',
      })
    }
  }, [result])

  return (
    <Route
      path={path}
      render={(props) => {
        // here you can apply condition
        const Component = component

        if (!token) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        if (currentWorkspace?.is_personal === 1 && location?.pathname === '/settings/members') {
          return <Redirect to={{ pathname: '/settings', state: { from: props.location } }} />
        }

        if (currentWorkspace?.is_personal === 1 && location?.pathname === '/scheduling/intro/workspace') {
          return <Redirect to={{ pathname: '/scheduling/intro/personal', state: { from: props.location } }} />
        }

        return (
          <>
            {user && !user?.email_verified_at && location?.pathname !== '/onboarding' && (
              <Modal
                overlayClassName="verify-modal-overlay"
                className="verify-modal-center"
                isOpen={user && !user?.email_verified_at && location?.pathname !== '/onboarding'}
              >
                <div className="verify-modal-content">
                  <div className="verify-header flex items-center flex-col space-y-6">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M25 50C11.215 50 0 38.7848 0 24.9998C0 11.2148 11.215 0 25 0C38.785 0 50 11.2148 50 24.9998C50 38.7848 38.785 50 25 50ZM25 4.54545C13.7212 4.54545 4.54545 13.7212 4.54545 24.9998C4.54545 36.2785 13.7212 45.4545 25 45.4545C36.2788 45.4545 45.4545 36.2785 45.4545 24.9998C45.4545 13.7212 36.2786 4.54545 25 4.54545Z"
                        fill="#000"
                      ></path>
                      <path
                        d="M24.9997 39.3939C23.3291 39.3939 21.97 38.0339 21.97 36.3623C21.97 34.6921 23.3291 33.3333 24.9997 33.3333C26.6703 33.3333 28.0294 34.6921 28.0294 36.3623C28.0294 38.0339 26.6703 39.3939 24.9997 39.3939Z"
                        fill="#000"
                      ></path>
                      <path
                        d="M25 28.7879C23.7448 28.7879 22.7273 27.7703 22.7273 26.5152V12.8788C22.7273 11.6236 23.7448 10.6061 25 10.6061C26.2552 10.6061 27.2727 11.6236 27.2727 12.8788V26.5152C27.2727 27.7703 26.2552 28.7879 25 28.7879Z"
                        fill="#000"
                      ></path>
                    </svg>
                    <h4 className="text-gray-800 mb-2">Please verify your email.</h4>
                    <div className="text-center pb-6">
                      <p className="cp-text-tiny text-gray-800 font-bold leading-6">
                        Please check your email for a verification link. If you did not receive the email,{' '}
                        <button
                          className="btn btn-link"
                          disabled={isLoading}
                          onClick={() =>
                            resend().then(({ data }) => {
                              if (data && data?.code === 200) {
                                alert('A fresh verification link has been sent to your email address.', {
                                  type: 'success',
                                })
                              }
                            })
                          }
                        >
                          click here to request another
                        </button>{' '}
                        or you can
                        <button className="btn btn-link" onClick={() => trigger()}>
                          logout.
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            <Component {...props} />
          </>
        )
      }}
    />
  )
}

export default Authmiddleware
