import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './AppRender.module.scss'
import { useReadNotificationsMutation } from '../../../modules/services/notificationsApi'
// import fromNow from '../../../lib/fromNow'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Notifications = (props) => {
  const { notifications, isOpen, setNotificationsOpen } = props
  const [trigger] = useReadNotificationsMutation()
  const [upToDate, setUpToDate] = useState(false)

  useEffect(() => {
    if (isOpen && notifications?.notifications && !!notifications?.notifications.length) {
      const unReaded = notifications?.notifications.reduce((acc, curr) => {
        if (!curr.read) {
          acc.push(curr?.id)
        }
        return acc
      }, [])
      if (unReaded && !!unReaded.length) {
        setUpToDate(true)
        trigger({
          body: {
            notifications: unReaded,
          },
        })
      }
    }
  }, [isOpen])

  return (
    <div className={cn(styles.notifications, isOpen ? 'absolute' : 'hidden')}>
      <div className={cn(styles.notifications_inner)}>
        {/* <div className="flex items-center justify-between p-4 w-full">
            <ul className="space-x-4 flex items-center">
              <li
                className={cn(
                  'border-b-2 border-transparent cursor-pointer pb-2',
                  tab === 'notifications' && 'border-cp-primary-100'
                )}
                onClick={() => setTab('notifications')}
              >
                <h4 className="cp-text-tiny font-bold">Notifications</h4>
              </li>
              <li
                className={cn(
                  'border-b-2 border-transparent cursor-pointer pb-2',
                  tab === 'announcements' && 'border-cp-primary-100'
                )}
                onClick={() => setTab('announcements')}
              >
                <h4 className="cp-text-tiny font-bold">Announcements</h4>
              </li>
            </ul>
            <span className="cursor-pointer" onClick={() => setNotificationsOpen(false)}>
              <i className="icon-close"></i>
            </span>
          </div> */}
        <div className="py-4 px-3 border-b border-cp-gray-10 dark:border-cp-gray-0 sticky top-0 bg-cp-white">
          <p className="small font-bold">Notifications</p>
        </div>
        <ul className="flex flex-col divide-y divide-cp-gray-10">
          {upToDate && (
            <li className="py-4 px-3 bg-cp-success-30">
              <div className="flex items-center">
                <span className="w-6 h-6 rounded-full bg-cp-success-100 flex items-center justify-center mr-2">
                  <i className="small icon-check text-white" />
                </span>
                <p className="small text-cp-success-100">You are up-to-date!</p>
              </div>
            </li>
          )}

          {notifications && notifications['notifications'] && !!notifications['notifications'].length ? (
            notifications['notifications'].map((item) => {
              return (
                <li
                  key={item?.id}
                  className={cn('py-4 px-3 hover:bg-cp-gray-10 dark:hover:bg-cp-gray-20', !item.read && 'bg-cp-gray-0')}
                >
                  <Link to={item.action_url}>
                    <div className="flex items-center">
                      {item.body && <p className="small flex-1">{item.body}</p>}
                      {item.created_at && (
                        <span className="xsmall text-cp-gray-40">{moment(item.created_at).fromNow()}</span>
                      )}
                    </div>
                  </Link>
                </li>
              )
            })
          ) : (
            <div className="py-4 px-3">
              <p className="small">No notification</p>
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Notifications
